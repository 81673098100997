import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import Navbar from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import H1 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H1';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import Link from '@bitcoin-portal/bitcoincom-pkg-components/dist/Link';

import SEO from '../../components/SEO';
import { appLocales } from '../../i18n';

const StyledH1 = styled(H1)`
  white-space: nowrap;

  ${media.md`
    white-space: normal;
  `}
`;

const ErrorPage = () => {
  const { messages, locale } = useIntl();

  return (
    <>
      <SEO page="404" />
      <Navbar
        locale={locale}
        buttons={messages['global.nav.buttons']}
        links={messages['global.nav.links']}
      />
      <Section>
        <ContentBlock
          aside={
            <Image
              src={messages['404.content.image']}
              alt={messages['404.content.image-alt']}
            />
          }
        >
          <StyledH1>
            <FormattedMessage id="404.content.title" />
          </StyledH1>
          <Paragraph>
            <FormattedMessage id="404.content.subtitle" />
          </Paragraph>
          <Paragraph>
            <FormattedMessage
              id="404.content.action"
              values={{
                home: (
                  <Link href="/">{messages['404.content.action-home']}</Link>
                ),
                contact: (
                  <Link href="mailto:contact@bitcoin.com">
                    {messages['404.content.action-contact']}
                  </Link>
                ),
              }}
            />
          </Paragraph>
        </ContentBlock>
      </Section>
      <Footer
        locale={locale}
        localeList={Object.keys(appLocales)}
        cookieName="bitcoinjp-locale"
        legal={messages['global.footer.legal']}
        links={messages['global.footer.links']}
        defaultLocale="ja"
      />
    </>
  );
};

export default ErrorPage;
