import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import H4 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H4';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import {
  StyledContentBlock,
  HeaderBlock,
  ImageWrapper,
  StyledImage,
} from './styled';

const BuySellTrade = () => {
  const { messages } = useIntl();
  const imgPath = messages['wallet.buy-sell-trade.image'].replace(
    '-sm.png',
    '',
  );

  return (
    <Section>
      <HeaderBlock>
        <H4 align="center">{messages['wallet.buy-sell-trade.header']}</H4>
      </HeaderBlock>
      <StyledContentBlock
        flip="both"
        style={{ alignItems: 'center' }}
        aside={
          <ImageWrapper>
            <StyledImage
              maxWidth="unset"
              maxHeight="unset"
              src={messages['wallet.buy-sell-trade.image']}
              alt="BuySellTrade"
              sizes="(max-width: 767px) 231w, (max-width: 1199px) 323w, (min-width: 1200px) 635w"
              srcSet={`${imgPath}-sm.png 231w, ${imgPath}-md.png 323w, ${imgPath}-sm@2x.png 462w, ${imgPath}-lg.png 635w, ${imgPath}-md@2x.png 646w, ${imgPath}-lg@2x.png 1270w`}
            />
          </ImageWrapper>
        }
      >
        <div>
          <H4>
            <FormattedMessage id="wallet.buy-sell-trade.title" />
          </H4>
          <Paragraph>
            <FormattedMessage id="wallet.buy-sell-trade.paragraph" />
          </Paragraph>
        </div>
      </StyledContentBlock>
    </Section>
  );
};

export default BuySellTrade;
