import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import H2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H2';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';

const Who = () => {
  const { messages } = useIntl();
  return (
    <Section>
      <ContentBlock
        style={{ alignItems: 'center' }}
        aside={
          <Image
            src={messages['jobs.who.image']}
            alt={messages['jobs.who.title']}
          />
        }
      >
        <H2>
          <FormattedMessage id="jobs.who.title" />
        </H2>
        <Paragraph>
          <FormattedMessage id="jobs.who.p1" />
        </Paragraph>
        <Paragraph>
          <FormattedMessage id="jobs.who.p2" />
        </Paragraph>
      </ContentBlock>
    </Section>
  );
};

export default Who;
