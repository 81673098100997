import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Item, SideBar } from './styled';
import scroll from '../../helpers/scroll';

class SideMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sticky: false,
      bottom: false,
      selected: '',
    };

    this.wrapperRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const selected = window.location.hash;

    const el = document.getElementById(selected.substring(1));
    if (el) window.scroll(0, el.getBoundingClientRect().top - 90);

    this.setState({
      selected,
    });

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (!this.wrapperRef || !this.wrapperRef.current) return;

    const { y } = this.wrapperRef.current.getBoundingClientRect();
    const { sticky, bottom } = this.state;
    const { contentRef, items } = this.props;
    items.forEach(item => {
      const el = document.getElementById(
        item.link.split('#')[item.link.split('#').length - 1],
      );
      if (!el) return;
      const yPos = el.getBoundingClientRect().top - 30;
      if (yPos > 32 && yPos < 120) {
        this.setState({
          selected: item.link,
        });
      }
    });

    if (sticky === true && y >= 32) {
      this.setState({ sticky: false });
    }

    if (contentRef && contentRef.current) {
      const { y: contentY, height: contentHeight } =
        contentRef.current.getBoundingClientRect();
      if (
        sticky === true &&
        Math.abs(contentY) > contentHeight - window.innerHeight
      ) {
        this.setState({ sticky: false, bottom: true });
      }

      if (
        bottom === true &&
        Math.abs(contentY) <= contentHeight - window.innerHeight
      ) {
        this.setState({ bottom: false, sticky: true });
      }

      if (
        sticky === false &&
        y < 72 &&
        Math.abs(contentY) <= contentHeight - window.innerHeight
      ) {
        this.setState({ sticky: true });
      }
    }
  }

  render() {
    const { items, ...rest } = this.props;
    const { sticky, bottom, selected } = this.state;

    return (
      <Wrapper ref={this.wrapperRef} bottom={bottom} {...rest}>
        <SideBar sticky={sticky}>
          {items.map(({ name, link, hash }) => (
            <Item
              key={name}
              selected={selected === link}
              onClick={scroll(hash, link)}
            >
              {name}
            </Item>
          ))}
        </SideBar>
      </Wrapper>
    );
  }
}

SideMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contentRef: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
};

export default SideMenu;
