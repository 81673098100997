import styled from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';

import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Link from '@bitcoin-portal/bitcoincom-pkg-components/dist/Link';

export const StyledSection = styled(Section)`
  ${media.md`
    padding-top: 40px;
  `}

  ${media.xl`
    padding-top: 48px;
  `}
`;

export const StyledContentBlock = styled(ContentBlock)`
  align-items: flex-start;

  ${media.md`
    align-items: center;
  `}
`;

export const ImageWrapper = styled.div`
  height: 318px;

  ${media.md`
    height: 400px;
  `}

  ${media.xl`
    height: 550px;
  `}
`;

export const HeaderText = styled.div`
  & > * {
    text-align: center;

    ${media.md`
      text-align: left;
    `}
  }

  & > h1 {
    ${media.md`
      padding-bottom: 24px;
    `}

    ${media.lg`
      padding-bottom: 24px;
    `}

    ${media.xl`
      padding-bottom: 32px;
    `}
  }

  & > p {
    color: ${colors.solid.textTwo};
    padding-bottom: 0;

    ${media.md`
      max-width: 420px;
    `}
  }
`;

export const Apps = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;

  ${media.md`
    justify-content: flex-start;
    margin: 32px 0;
  `}

  ${media.xl`
    justify-content: flex-start;
    margin: 48px 0;
  `}

  & > a {
    max-width: 120px;
    min-height: 42px;
    margin: ${({ theme }) => theme.spacing.unit}px;

    ${media.md`
      max-width: 135px;
      margin: 0;
      margin-right: 20px;
    `}

    ${media.lg`
      max-width: 180px;
    `}

    ${media.xl`
      max-width: 200px;
    `}
  }

  & > a > img {
    width: auto;
    height: auto;
    max-width: 120px;
  }

  ${media.md`
    justify-content: flex-start;
    & > a > img {
      max-width: unset;
      height: unset;
      width: 100%;
    }
  `}
`;

export const SmsLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.tertiary};
  margin-top: 20px !important;
  display: block;
  background: none;
  background-clip: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  text-align: center;
  font-size: 16px;

  ${media.md`
    text-align: left;
  `}

  ${media.xl`
    font-size: 20px;
  `}
`;

export const MediaWrapper = styled(ContentBlock)`
  display: none;

  ${media.md`
    display: flex;
    align-items: flex-start;
    padding-top: 80px;
  `}

  ${media.xl`
    align-items: center;
  `}
`;

export const LoginButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 20px auto;
  max-width: 300px;
  > div {
    margin-bottom: ${({ theme }) => theme.spacing.unit}px;
  }
  ${media.md`
    margin: 32px 0;
  `}
  ${media.xl`
    margin: 48px 0;
  `}
`;
