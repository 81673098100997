import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import FlexContainer from '@bitcoin-portal/bitcoincom-pkg-components/dist/FlexContainer';
import H4 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H4';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import {
  Heading,
  StyledCard,
  StyledImage,
  CardTitle,
  CardText,
} from '../styled';

const Why = () => {
  const { messages } = useIntl();

  const steps = messages['buy.why.steps'] || [];

  return (
    <Section>
      <ContentBlock>
        <Heading>
          <H4 align="center">
            <FormattedMessage id="buy.why.title" />
          </H4>
          <Paragraph>
            <FormattedMessage id="buy.why.subtitle" />
          </Paragraph>
        </Heading>
        <FlexContainer
          style={{
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginTop: 24,
          }}
        >
          {steps.map(({ title, description, image }) => {
            return (
              <StyledCard key={title}>
                <div>
                  <StyledImage src={image} maxWidth={50} maxHeight={50} />
                  <CardTitle bold>{title}</CardTitle>
                </div>
                <CardText>{description}</CardText>
              </StyledCard>
            );
          })}
        </FlexContainer>
      </ContentBlock>
    </Section>
  );
};

export default Why;
