import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import capitalize from 'lodash/capitalize';
import ComboWidget from '@bitcoin-portal/bitcoincom-pkg-components/dist/ComboWidget';
import CreditCard from '@bitcoin-portal/bitcoincom-pkg-components/dist/IconsNavigation/CreditCard';
import H4 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H4';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import {
  sendAmpEvent,
  sendAmpUserProps,
  sendGaEvent,
} from '../../../helpers/analytics';
import {
  StyledSection,
  Heading,
  Subheading,
  StyledButton,
  StyledText,
  ImageWrapper,
  StyledContentBlock,
} from './styled';

const Buy = ({ locale }) => {
  const { messages } = useIntl();
  const [providerTracked, setProviderTracked] = useState(false);

  const imgPath = messages['home.buy.image'].replace('-sm.png', '');

  return (
    <StyledSection>
      <Heading>
        <H4 style={{ marginBottom: 16 }}>
          <FormattedMessage id="home.buy.section-title" />
        </H4>
        <Subheading>
          <FormattedMessage id="home.buy.section-subtitle" />
        </Subheading>
        <StyledButton
          href={messages['home.buy.mobile-link']}
          design="secondary"
        >
          {messages['home.buy.mobile-buy']}
          <CreditCard
            height={24}
            style={{ alignSelf: 'center', marginLeft: '11px' }}
          />
        </StyledButton>
        <StyledText bold>{messages['home.buy.highlight']}</StyledText>
      </Heading>
      <StyledContentBlock
        className="homepage-buy"
        aside={
          <ComboWidget
            buyLocales={{
              buyTab: messages['home.buy-locales.tab'],
              buy: messages['home.buy-locales.buy'],
              spend: messages['home.buy-locales.spend'],
              amount: messages['home.buy-locales.amount'],
              button: messages['home.buy-locales.button'],
              exit: messages['home.buy-locales.exit'],
              min: messages['home.buy-locales.min'],
              max: messages['home.buy-locales.max'],
              locale: locale !== 'en' ? locale : undefined,
            }}
            sellLocales={{
              sellTab: messages['home.sell-locales.tab'],
              buy: messages['home.sell-locales.buy'],
              spend: messages['home.sell-locales.spend'],
              amount: messages['home.sell-locales.amount'],
              button: messages['home.sell-locales.button'],
              exit: messages['home.sell-locales.exit'],
              min: messages['home.sell-locales.min'],
              max: messages['home.sell-locales.max'],
              locale: locale !== 'en' ? locale : undefined,
              disclaimer: messages['home.sell-locales.disclaimer'],
            }}
            externalTransactionId="bitcoinjp"
            clickFn={({ action, crypto, sell }) => {
              if (action === 'open') {
                if (!sell) {
                  sendAmpEvent({
                    label: 'buy_button_clicked',
                    properties: {
                      coin: crypto,
                      pageview: window.location.href,
                    },
                  });
                }
                sendGaEvent({
                  category: 'Combo widget',
                  action: sell ? 'Sell' : 'Buy',
                  label: crypto,
                });
              }
            }}
            providerFn={(p, sell) => {
              if (providerTracked === true) return;
              sendAmpUserProps({
                provider: p,
                widget: 'vertical',
              });
              sendGaEvent({
                category: 'Combo Widget',
                action: sell ? 'Load sell' : 'Load buy',
                label: capitalize(p),
              });
              setProviderTracked(true);
            }}
          />
        }
      >
        <ImageWrapper>
          <Image
            maxWidth="unset"
            maxHeight="inherit"
            src={messages['home.buy.image']}
            alt={messages['home.buy.image-alt']}
            sizes="(max-width: 767px) 260w, (max-width: 1199px) 327w, (min-width: 1200px) 450w"
            srcSet={`${imgPath}-sm.png 260w, ${imgPath}-md.png 327w, ${imgPath}-lg.png 450w, ${imgPath}-sm@2x.png 520w, ${imgPath}-md@2x.png 655w, ${imgPath}-lg@2x.png 900w`}
          />
        </ImageWrapper>
      </StyledContentBlock>
    </StyledSection>
  );
};

Buy.propTypes = {
  locale: PropTypes.string,
};

Buy.defaultProps = {
  locale: 'ja',
};

export default Buy;
