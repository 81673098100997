import styled from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';

export const Title = styled.header`
  text-align: center;
  display: flex;
  justify-content: center;

  h1 {
    max-width: 800px;
  }
`;

export const StyledContentBlock = styled.div`
  div:first-child {
    padding-bottom: 0;
    min-height: 1200px;
  }
`;

export const MenuWrapper = styled(ContentBlock)`
  & > div {
    width: 100%;
    overflow-y: unset;
  }

  & .mlt-tablist {
    grid-template-columns: 1fr;
  }

  & .mlt-tab {
    font-size: 16px !important;
    height: 40px;
    justify-content: center;
    text-align: center;
    margin: 0 30px;
    padding: 0;
  }

  & .selected {
    border-left: 5px solid ${colors.solid.primaryBlue};
  }

  & > div > div > div:last-child {
    display: none;
  }

  ${media.md`
    & > div {
      margin: auto;
      width: fit-content;
      overflow-y: auto;
    }

    & .mlt-tablist {
      grid-template-columns: repeat(6, 1fr);
    }

    & .mlt-tab {
      font-size: 20px !important;
      height: 80px;
      justify-content: center;
      text-align: center;
      margin: 0;
      padding: 0 10px;
    }

    & .selected {
      border-left: unset;
    }

    & > div > div > div:last-child {
      display: flex;
    }
  `}
`;
