import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const SEO = () => {
  const { locale } = useIntl();

  return (
    <Helmet>
      <html lang={locale} />
      <meta name="application-name" content="Bitcoin.com" />
      <meta name="msapplication-TileColor" content="#111724" />
    </Helmet>
  );
};

export default SEO;
