import styled from 'styled-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const HeadSection = styled.div`
  overflow: hidden;
`;

export const CardContainer = styled.div`
  display: grid;
  grid-column-gap: ${({ theme }) => theme.spacing.unit * 3}px;
  grid-row-gap: ${({ theme }) => theme.spacing.unit * 3}px;
  position: relative;
  grid-template-columns: 1fr;

  ${media.md`
   grid-template-columns: repeat(2, 1fr);
  `}
`;

export const Card = styled.a`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.unit * 4}px;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  background: white;
  position: relative;
  z-index: 0;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.default};
  justify-content: space-between;

  & > ::before {
    position: absolute;
    content: '';
    border-radius: 6px;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    box-shadow: 0px 71px 100px 0px rgba(0, 0, 0, 0.09);
  }
`;

export const Paragraph = styled.p`
  padding: 0;
  margin: 0;
  text-align: left;

  color: rgb(137, 141, 157);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 20px;
`;

export const H4 = styled.h4`
  margin: 0;
  color: rgb(19, 23, 32);
  padding-bottom: ${({ theme }) => theme.spacing.unit}px;
`;

export const H2 = styled.h2`
  color: rgb(19, 23, 32);
  text-align: left;
  margin-bottom: 40px;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0px;
`;

export const Image = styled.img`
  width: 80px;
  object-fit: contain;
  margin-left: ${({ theme }) => theme.spacing.unit * 3}px;
`;
