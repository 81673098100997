import styled, { css } from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';

export const Wrapper = styled.div`
  h2 {
    scroll-margin-top: 90px;
    font-size: 30px;
    line-height: 38px;
  }

  p,
  li,
  tr {
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
  }

  ${({ theme }) => css`
    width: 100%;
    position: relative;

    & > section {
      align-items: flex-start;
      padding-top: ${theme.spacing.unit * 4}px;
      padding-bottom: ${theme.spacing.unit * 4}px;
    }

    ${media.md`
    & > section {
      padding-top: ${theme.spacing.unit * 8}px;
      padding-bottom: ${theme.spacing.unit * 8}px;
    }
  `}
  `}
`;

export const Title = styled.header`
  text-align: center;
`;

export const StyledContentBlock = styled(ContentBlock)`
  & > :last-child {
    align-self: stretch;
    display: flex;
    flex-basis: 20%;
    min-width: 250px;

    ${media.md`
      flex-basis: 30%;
    `}
  }

  & > :first-child {
    flex-basis: 100%;
    text-align: left;
    overflow: auto;

    ${media.md`
      flex-basis: 80%;
    `}
  }
`;

export const MenuWrapper = styled(ContentBlock)`
  & > div {
    width: 100%;
    overflow-y: unset;
  }

  & .mlt-tablist {
    grid-template-columns: 1fr;
  }

  & .mlt-tab {
    font-size: 16px !important;
    height: 40px;
    justify-content: center;
    text-align: center;
    margin: 0 30px;
    padding: 0;
  }

  & .selected {
    border-left: 5px solid ${colors.solid.primaryBlue};
  }

  & > div > div > div:last-child {
    display: none;
  }

  ${media.md`
    & > div {
      margin: auto;
      width: fit-content;
      overflow-y: auto;
    }

    & .mlt-tablist {
      grid-template-columns: repeat(6, 1fr);
    }

    & .mlt-tab {
      font-size: 20px !important;
      height: 80px;
      justify-content: center;
      text-align: center;
      margin: 0;
      padding: 0 10px;
    }

    & .selected {
      border-left: unset;
    }

    & > div > div > div:last-child {
      display: flex;
    }
  `}
`;
