import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import H2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H2';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';

const What = () => {
  const { messages } = useIntl();
  return (
    <Section>
      <ContentBlock
        flip={ContentBlock.Flips.Vertical}
        style={{ alignItems: 'center' }}
        aside={
          <Image
            src={messages['about-us.what.image']}
            alt={messages['about-us.what.image-alt']}
          />
        }
      >
        <H2>
          <FormattedMessage id="about-us.what.title" />
        </H2>
        <Paragraph>
          <FormattedMessage
            id="about-us.what.description"
            values={{
              // eslint-disable-next-line react/no-unstable-nested-components
              STRONG: (...chunks) => <strong>{chunks}</strong>,
            }}
          />
        </Paragraph>
        {/* <Button
          link
          design={Button.Designs.Primary}
          href={messages['about-us.what.button-link']}
        >
          <FormattedMessage id="about-us.what.button-text" />
        </Button> */}
      </ContentBlock>
    </Section>
  );
};

export default What;
