import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

const SEO = () => {
  const intl = useIntl();
  return (
    <Helmet title={intl.formatMessage({ id: 'sitemap.meta.title' })}>
      <meta
        name="description"
        content={intl.formatMessage({
          id: 'sitemap.meta.description',
        })}
      />
      <meta
        property="og:title"
        content={intl.formatMessage({ id: 'sitemap.meta.title' })}
      />
      <meta
        property="og:description"
        content={intl.formatMessage({
          id: 'sitemap.meta.description',
        })}
      />
      <meta
        property="og:image"
        content={`https://www.bitcoin.jp${intl.formatMessage({
          id: 'sitemap.meta.image',
        })}`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:site"
        content={`@${intl.formatMessage({ id: 'sitemap.meta.twitter' })}`}
      />
      <meta
        name="twitter:title"
        content={intl.formatMessage({ id: 'sitemap.meta.title' })}
      />
      <meta
        name="twitter:description"
        content={intl.formatMessage({
          id: 'sitemap.meta.description',
        })}
      />
      <meta
        name="twitter:image"
        content={`https://www.bitcoin.jp${intl.formatMessage({
          id: 'sitemap.meta.image',
        })}`}
      />
      <link rel="canonical" href="https://www.bitcoin.jp/sitemap/" />
    </Helmet>
  );
};

export default SEO;
