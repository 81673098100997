import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import H2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H2';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';

const SoundMoney = () => {
  const { messages } = useIntl();
  return (
    <Section>
      <ContentBlock
        flip={ContentBlock.Flips.Both}
        style={{ alignItems: 'center' }}
        aside={
          <Image
            src={messages['about-us.sound.image']}
            alt={messages['about-us.sound.image-alt']}
          />
        }
      >
        <H2>
          <FormattedMessage id="about-us.sound.title" />
        </H2>
        <Paragraph>
          <FormattedMessage id="about-us.sound.description" />
        </Paragraph>
        {/* <Button
          link
          design={Button.Designs.Primary}
          href={messages['about-us.sound.button-link']}
        >
          <FormattedMessage id="about-us.sound.button-text" />
        </Button> */}
      </ContentBlock>
    </Section>
  );
};

export default SoundMoney;
