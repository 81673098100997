import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import H2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H2';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';

const Join = () => {
  const { messages } = useIntl();
  return (
    <Section>
      <ContentBlock
        flip={ContentBlock.Flips.Both}
        style={{ alignItems: 'center' }}
        aside={
          <Image
            src={messages['about-us.join.image']}
            alt={messages['about-us.join.image-alt']}
          />
        }
      >
        <H2>
          <FormattedMessage id="about-us.join.title" />
        </H2>
        <Paragraph>
          <FormattedMessage id="about-us.join.description" />
        </Paragraph>
        <Button
          design={Button.Designs.Primary}
          href={messages['about-us.join.button-link']}
        >
          <FormattedMessage id="about-us.join.button" />
        </Button>
      </ContentBlock>
    </Section>
  );
};

export default Join;
