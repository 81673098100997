import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

const SEO = ({ page, children }) => {
  const { messages } = useIntl();
  return (
    <Helmet title={messages[`${page}.meta.title`]}>
      <meta name="description" content={messages[`${page}.meta.description`]} />
      <meta property="og:title" content={messages[`${page}.meta.title`]} />
      <meta
        property="og:description"
        content={messages[`${page}.meta.description`]}
      />
      <meta
        property="og:image"
        content={`https://www.bitcoin.jp${messages[`${page}.meta.image`]}`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:site"
        content={`@${messages[`${page}.meta.twitter`]}`}
      />
      <meta name="twitter:title" content={messages[`${page}.meta.title`]} />
      <meta
        name="twitter:description"
        content={messages[`${page}.meta.description`]}
      />
      <meta
        name="twitter:image"
        content={`https://www.bitcoin.jp${messages[`${page}.meta.image`]}`}
      />
      <link rel="canonical" href={`https://www.bitcoin.jp/${page}/`} />
      {children}
    </Helmet>
  );
};

SEO.propTypes = {
  page: PropTypes.string.isRequired,
  children: PropTypes.node,
};

SEO.defaultProps = {
  children: undefined,
};

export default SEO;
