import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import H4 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H4';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import { StyledContentBlock, ImageWrapper, StyledImage } from './styled';

const ERC = () => {
  const { messages } = useIntl();
  const imgPath = messages['wallet.erc.image'].replace('-sm.png', '');

  return (
    <Section>
      <StyledContentBlock
        flip="both"
        style={{ alignItems: 'center' }}
        aside={
          <ImageWrapper>
            <StyledImage
              maxWidth="unset"
              maxHeight="unset"
              src={messages['wallet.erc.image']}
              alt="BuySellTrade"
              sizes="(max-width: 767px) 231w, (max-width: 1199px) 323w, (min-width: 1200px) 635w"
              srcSet={`${imgPath}-sm.png 231w, ${imgPath}-md.png 323w, ${imgPath}-sm@2x.png 462w, ${imgPath}-lg.png 635w, ${imgPath}-md@2x.png 646w, ${imgPath}-lg@2x.png 1270w`}
            />
          </ImageWrapper>
        }
      >
        <div>
          <H4>
            <FormattedMessage id="wallet.erc.title" />
          </H4>
          <Paragraph>
            <FormattedMessage id="wallet.erc.paragraph" />
          </Paragraph>
        </div>
      </StyledContentBlock>
    </Section>
  );
};

export default ERC;
