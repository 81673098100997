import styled from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';

export const Section = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 16px;
  box-sizing: border-box;
  ${media.md`
    padding: 135px 32px;
    align-items: center;
  `}
  ${media.lg`
    padding: 135px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 30px !important;
`;

export const ListCtn = styled.div`
  flex-basis: 100%;
  padding: 10px 0;
  box-sizing: border-box;

  ${media.md`
   flex-basis: 50%;

   &:nth-child(odd) {
  padding-right: 15px;
  }

  &:nth-child(even) {
  padding-left: 15px;
  }
  `}
`;

export const Postings = styled.div`
  text-align: left;
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  background: ${colors.solid.bgWhite};
  border-radius: 24px;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  margin: 0px;
  transition: all 0.2s;

  &:hover {
    background-color: ${colors.solid.primaryBlue};
    color: white;
  }
`;

export const PostingsLink = styled.a`
  text-decoration: none;
  color: ${colors.solid.textOne};
  width: 100%;
`;

export const Dept = styled(Paragraph)`
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  margin-top: 5px !important;
  ${media.md`

  `}
`;

export const PostTitle = styled.h3`
  margin: 0;
  padding: 0;
  text-decoration: none;
`;

export const JobsSection = styled(Section)`
  ${media.md`
    align-items: center !important;
  `}
`;
