import React from 'react';
import { injectIntl, IntlContext } from 'react-intl';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import H2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H2';
import H3 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H3';
import HR from '@bitcoin-portal/bitcoincom-pkg-components/dist/HR';
import Link from '@bitcoin-portal/bitcoincom-pkg-components/dist/Link';
import { Container, MainPage, SectionContainer } from './styled';

const Sites = ({ intl: { messages } }) => {
  const sections = messages['sitemap.sections'];

  return (
    <Section>
      <ContentBlock>
        <Container>
          {sections &&
            sections.map(section => (
              <React.Fragment key={section.title}>
                <H2>{section.title}</H2>
                <SectionContainer>
                  {section.pages &&
                    section.pages.map(page => (
                      <MainPage key={page.url + page.title}>
                        <H3>{page.title}</H3>
                        {page.pages &&
                          page.pages.map(subpage => (
                            <div key={subpage.title + subpage.url}>
                              <Link href={subpage.url}>{subpage.title}</Link>
                            </div>
                          ))}
                      </MainPage>
                    ))}
                </SectionContainer>
                <HR />
              </React.Fragment>
            ))}
        </Container>
      </ContentBlock>
    </Section>
  );
};

Sites.propTypes = {
  intl: IntlContext.isRequired,
};

export default injectIntl(Sites);
