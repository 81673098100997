import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, image, twitter }) => (
  <Helmet title={title}>
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={`https://www.bitcoin.jp${image}`} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content={`@${twitter}`} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={`https://www.bitcoin.jp${image}`} />
    <link rel="canonical" href="https://www.bitcoin.jp/legal/faq/" />
  </Helmet>
);

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default SEO;
