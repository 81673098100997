import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import PrivacyPolicy from '../../views/Legal/PrivacyPolicy';

const PrivacyPolicyPage = props => {
  const {
    pageContext: { locale },
    location: { pathname },
    data: {
      allMarkdownRemark: { edges },
    },
  } = props;

  return (
    <Layout locale={locale} localeDir={pathname}>
      <PrivacyPolicy data={edges[0]} locale={locale} />
    </Layout>
  );
};

PrivacyPolicyPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    locales: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    }),
    content: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query ($locale: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: { locale: { eq: $locale } }
        fileAbsolutePath: { glob: "**/src/i18n/legal/privacy-policy/*.md" }
      }
    ) {
      edges {
        node {
          headings {
            value
          }
          htmlAst
          frontmatter {
            title
            locale
            metatitle
            metadescription
            metatwitter
            metaimage
            date
          }
        }
      }
    }
  }
`;

PrivacyPolicyPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({ edges: PropTypes.array }),
  }).isRequired,
};

export default PrivacyPolicyPage;
