import styled from 'styled-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Card from '@bitcoin-portal/bitcoincom-pkg-components/dist/Card';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;

  span {
    display: inline-flex !important;
    justify-content: center !important;
  }

  ${media.xl`

    & > span {
      align-items: center;
    }
  `}
`;

export const StyledImage = styled(Image)`
  filter: drop-shadow(0px 10px 20px rgba(39, 147, 255, 0.6));
  margin-bottom: 40px;
  width: 200px;
  height: 233px;

  ${media.md`
    margin-bottom: 0;
  `}

  ${media.lg`
    filter: drop-shadow(0px 20px 40px rgba(39, 147, 255, 0.6));
    width: 250px;
    height: 292px;
  `}

  ${media.xl`
    width: 340px;
    height: 397px;
  `}
`;

export const StyledCard = styled(Card)`
  margin: 0px ${({ theme }) => theme.spacing.unit * 3}px;

  ${media.md`
    margin: 0px auto;
    flex-flow: row nowrap;
    height: 100%;
    width: 624px;
    padding: 30px;

    & > div {
      flex-direction: row;
      display: flex;
      padding: 0px;

      & > div:first-child {
        justify-content: center;
        align-items: center;
     }
    }
  `}

  ${media.lg`
    flex-flow: row nowrap;
    width: 824px;
    padding: 48px;
  `}

  ${media.xl`
    width: 1027px;
    height: 536px;
    padding: 64px;

    & > div:first-child {
      align-self: flex-end;
    }
  `}
`;

export const Apps = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding-top: 8px;

  ${media.lg`
    margin: ${({ theme }) => theme.spacing.unit * 4}px 0;
  `}

  & > div {
    display: flex;
    flex-flow: row nowrap;
  }

  a {
    max-width: 120px;
    min-height: 42px;
    margin: 0 ${({ theme }) => theme.spacing.unit}px 0 0;
    font-size: 16px;

    ${media.lg`
      max-width: 180px;
      font-size: 20px;
    `}

    ${media.xl`
      margin: 0;
      margin-right: 20px;
    `}
  }

  a > img {
    width: auto;
    height: auto;
    max-width: 120px;
  }

  ${media.lg`
    justify-content: flex-start;
    & > a > img {
      max-width: unset;
      height: unset;
      width: 100%;
    }
  `}
`;

export const Content = styled.div`
  margin: 0px;
  align-self: center;

  h5 {
    padding-bottom: ${({ theme }) => theme.spacing.unit}px;
    font-size: 24px;
  }

  .description {
    padding-bottom: 24px;
  }

  ${media.md`
    padding-left: 30px;

    h5 {
    font-size: 20px;
  }

    .description {
      padding-top: 0;
    }

    p {
      font-size: 13px;
    }
  `}

  ${media.lg`
    padding-left: 60px;

    .description {
      padding-bottom: 24px;
      margin-top: 24px;
    }

    h5 {
      padding-bottom: 16px;
      font-size: 28px;
    }

    p {
      padding-bottom: 0;
      font-size: 16px;
      line-height: 28px;
    }
  `}

  ${media.xl`
    padding-left: 80px;

    h5 {
      font-size: 32px;
      line-height: 40px;
    }

    & > p.description {
      font-size: 18px;
      line-height: 32px;
    }
  `}
`;
