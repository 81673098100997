const getYPos = el => {
  let y = 0;
  while (el.offsetParent) {
    y += el.offsetTop;
    // eslint-disable-next-line no-param-reassign
    el = el.offsetParent;
  }
  return y;
};

const scroll = (hash, link) => {
  return () => {
    // eslint-disable-next-line no-param-reassign
    hash = hash.includes('#') ? hash.substring(1) : hash;
    const el = document.getElementById(hash);
    if (link) window.location.href = link;
    window.scrollTo(0, getYPos(el) - 90);
  };
};

export default scroll;
