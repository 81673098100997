import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import H2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H2';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import { Wrapper } from './styled';

const Counter = () => {
  const { messages } = useIntl();

  const errvalue = messages['home.header.value'];
  const [value, setValue] = useState(<span>{errvalue}</span>);

  useEffect(() => {
    const getWalletCount = async () => {
      try {
        const result = await axios.get(messages['home.header.api.walletCount']);
        const numWallets = result.data.totalWalletsCreated;
        const roundedNum = Math.floor(numWallets / 1000) * 1000;
        const val =
          roundedNum && typeof roundedNum === 'number' ? (
            <NumberFormat
              value={roundedNum}
              displayType="text"
              thousandSeparator
            />
          ) : (
            <span>{errvalue}</span>
          );

        setValue(val);
      } catch (error) {
        setValue(<span>{errvalue}</span>);
      }
    };

    getWalletCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <ContentBlock
        aside={
          <Button design="secondary" href={messages['home.header.cta']}>
            {messages['home.header.counter-button']}
          </Button>
        }
      >
        <H2 contrast>
          <FormattedMessage
            tagName="span"
            id="home.header.wallets"
            values={{ value }}
          />
        </H2>
      </ContentBlock>
    </Wrapper>
  );
};

export default Counter;
