import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import Wallet from '../views/Wallet';

const IndexPage = props => {
  const {
    pageContext: { locale },
    location: { pathname },
  } = props;

  return (
    <Layout locale={locale} localeDir={pathname}>
      <Wallet locale={locale} />
    </Layout>
  );
};

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default IndexPage;
