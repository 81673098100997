import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import Link from '@bitcoin-portal/bitcoincom-pkg-components/dist/Link';

const Contact = () => {
  const { messages } = useIntl();
  return (
    <Section>
      <ContentBlock
        flip={ContentBlock.Flips.Vertical}
        aside={
          <Image
            src={messages['contact-us.content.image']}
            alt={messages['contact-us.content.imageAlt']}
          />
        }
      >
        {messages['contact-us.content.text'].map(p => (
          <Paragraph key={p}>
            <FormattedMessage
              id="contact-us.content.text.item"
              defaultMessage={p}
              values={{
                forum: (
                  <Link
                    href={messages['contact-us.content.forum-link']}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {messages['contact-us.content.forum']}
                  </Link>
                ),
              }}
            />
          </Paragraph>
        ))}
        <Button
          design={Button.Designs.Primary}
          newTab
          href={messages['contact-us.content.link']}
        >
          {messages['contact-us.content.button']}
        </Button>
      </ContentBlock>
    </Section>
  );
};

export default Contact;
