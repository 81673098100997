import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Navbar from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';
import { useIntl } from 'react-intl';
import BuyModal from '@bitcoin-portal/bitcoincom-pkg-components/dist/BuyModal';
import SEO from '../../components/SEO';
import { appLocales } from '../../i18n';
import Header from './Header';
import CryptoList from './CryptoList';
import QuickGuide from './QuickGuide';
import Why from './Why';
import Wallet from './Wallet';
import { sendGaEvent } from '../../helpers/analytics';

const Buy = ({ crypto, provider }) => {
  const { locale, messages } = useIntl();
  const buyRef = useRef();
  const [selectedCrypto, setSelectedCrypto] = useState(crypto);
  const [cryptos, setCryptos] = useState([]);
  const [openProvider, setOpenProvider] = useState(provider);

  const openModal = (symbol, prov) => {
    setOpenProvider(prov);
    setSelectedCrypto(symbol.toLowerCase());
    if (!buyRef || !buyRef.current) return;
    buyRef.current.open();
  };

  return (
    <div>
      <Navbar
        locale={locale}
        buttons={messages['global.nav.buttons']}
        links={messages['global.nav.links']}
      />
      <SEO page="buy" />
      <Header
        provider={provider}
        setCryptos={setCryptos}
        buyRef={buyRef}
        crypto={crypto}
      />
      <Why />
      {cryptos.length > 0 && (
        <CryptoList
          provider={provider}
          openModal={openModal}
          cryptos={cryptos}
        />
      )}
      <QuickGuide />
      <Wallet />
      <BuyModal
        locale={locale}
        exitText={messages['buy.buy.exit']}
        modalRef={buyRef}
        env={process.env.DEPLOY_ENV}
        provider={provider || openProvider}
        callback={res => {
          if (res.action === 'open')
            sendGaEvent({
              category: 'buy',
              action: 'Buy button',
              label: selectedCrypto || 'buy btn generic',
            });
        }}
        crypto={selectedCrypto}
      />
      <Footer
        locale={locale}
        localeList={Object.keys(appLocales)}
        cookieName="bitcoinjp-locale"
        legal={messages['global.footer.legal']}
        links={messages['global.footer.links']}
        defaultLocale="ja"
      />
    </div>
  );
};

Buy.propTypes = {
  crypto: PropTypes.oneOf(['eth', 'btc', 'bch', 'ltc', 'xrp', 'bnb', 'xlm']),
  provider: PropTypes.string,
};

Buy.defaultProps = {
  crypto: undefined,
  provider: 'simplex',
};

export default Buy;
