import styled from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Card from '@bitcoin-portal/bitcoincom-pkg-components/dist/Card';
import Grid from '@bitcoin-portal/bitcoincom-pkg-components/dist/Grid';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column nowrap;
  padding-bottom: 24px;

  a {
    display: none;
  }

  & > div > p {
    padding-bottom: 0;
  }

  ${media.md`
    margin-bottom: 40px;
    flex-flow: row nowrap;

    a {
      display: flex;
    }
  `}
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${colors.solid.textTwo};
  font-size: 16px;
  line-height: 28px;

  ${media.md`
    font-size: 18px;
    line-height: 32px;
    width: 70%;
  `}

  ${media.xl`
    font-size: 20px;
    line-height: 40px;
    width: 100%;
  `}
`;

export const StyledGrid = styled(Grid)`
  row-gap: 0;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 24px;

  & > div:last-child {
    border-radius: 0 0 24px 24px;
  }

  ${media.md`
    box-shadow: none;
    border-radius: none;

    #mobile-markets {
      display: none;
    }
  `}
`;

export const StyledCard = styled(Card)`
  width: 100%;
  display: ${props => (props.index !== 3 ? 'flex' : 'none')};
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid rgba(212, 217, 232, 0.3);
  padding: 18px 16px;

  :first-child {
    border-radius: 24px 24px 0 0;
  }

  ${media.md`
    border-radius: 24px;
    border-bottom: 0;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 24px;

    a:first-child, :first-child {
      border-radius: 24px;
    }
  `}

  ${props =>
    media.xl`
    display: ${props.index <= 3 ? 'flex' : 'none'};
  `}
`;

export const ChartWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: space-between;
  object-fit: contain;

  :last-child {
    justify-content: center;
  }

  ${media.md`
    flex-flow: column nowrap;
  `}
`;

export const Ticker = styled.div`
  color: ${colors.solid.textThree};
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;

  ${media.md`
    font-size: 14px;
    line-height: 24px;
  `}
`;

export const Label = styled.div`
  color: black;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  ${media.md`
    font-size: 18px;
  `}
`;

export const Header = styled.div`
  display: flex;
  text-align: left;
  justify-content: flex-start;
  width: 100%;

  & > div:first-child {
    align-self: center;
  }
`;

export const ChartArea = styled.div`
  display: none;

  ${media.md`
    display: flex;
    margin: 24px 0 32px;
    width: 100%;

    & * {
      cursor: pointer !important;
    }
  `}
`;

export const PriceArea = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: flex-end;
  flex-flow: column nowrap;

  ${media.md`
    align-items: flex-start;
    width: 100%;
  `}

  ${media.xl`
    flex-flow: row nowrap;
    align-items: flex-end;
  `}
`;

export const Price = styled.div`
  color: black;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 22px;

  ${media.lg`
    line-height: 40px;
    font-size: 24px;
  `}
`;
export const Change = styled.div`
  color: ${({ positive }) =>
    positive ? colors.solid.primaryGreen : colors.solid.primaryRed};

  font-weight: 800;
  font-size: 13px;
  line-height: 24px;

  ${media.md`
    font-size: 16px;
    line-height: 16px;
  `}

  ${media.xl`
    font-size: 16px;
    line-height: 30px;
  `}
`;

export const StyledButton = styled(Button)`
  & > span {
    display: flex;
    align-content: center;
  }

  ${media.md`
    display: none;
  `}
`;
