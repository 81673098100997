import React from 'react';
import { FormattedMessage } from 'react-intl';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import H1 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H1';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';

const Header = () => (
  <Section>
    <ContentBlock>
      <H1 align="center">
        <FormattedMessage id="contact-us.header.title" />
      </H1>
      <Paragraph bold align="center" style={{ maxWidth: 700, margin: 'auto' }}>
        <FormattedMessage id="contact-us.header.subtitle" />
      </Paragraph>
    </ContentBlock>
  </Section>
);

export default Header;
