import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AreaChart, Area, YAxis, ResponsiveContainer } from 'recharts';
import Loader from '@bitcoin-portal/bitcoincom-pkg-components/dist/Loader';

const Chart = ({ graphData }) => {
  const [min, setMin] = useState(1000);
  const [max, setMax] = useState(0);
  const [stroke, setStroke] = useState('');

  useEffect(() => {
    setMax(Math.max(...graphData.map(o => o.open), 0));
    setMin(Math.min(...graphData.map(o => o.open), max));
    if (graphData.length > 0) {
      setStroke(
        graphData[0].open < graphData[graphData.length - 1].open
          ? '#0ac18e'
          : '#F74909',
      );
    }
  }, [max, setMin, setMax, graphData, setStroke]);

  if (graphData.length < 1)
    return (
      <div style={{ height: 49, width: 134, textAlign: 'center' }}>
        <Loader />
      </div>
    );

  return (
    <ResponsiveContainer debounce={1} height={50}>
      <AreaChart
        data={graphData}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="fillGreen" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0ac18e" stopOpacity={0.4} />
            <stop offset="95%" stopColor="#0ac18e" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="fillRed" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#F74909" stopOpacity={0.4} />
            <stop offset="95%" stopColor="#F74909" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          isAnimationActive={false}
          type="monotone"
          dataKey="open"
          stroke={stroke}
          strokeWidth={1}
          dot={false}
          fill={stroke === '#0ac18e' ? 'url(#fillGreen)' : 'url(#fillRed)'}
          fillOpacity={1}
        />
        <YAxis hide domain={[min, max]} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

Chart.propTypes = {
  graphData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Chart;
