import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import DsarForm from '../../views/Legal/DsarForm';

const DsarFormPage = props => {
  const {
    pageContext: { locale },
    location: { pathname },
  } = props;

  return (
    <Layout locale={locale} localeDir={pathname}>
      <DsarForm locale={locale} />
    </Layout>
  );
};

DsarFormPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    locales: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default DsarFormPage;
