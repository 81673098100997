exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-buy-js": () => import("./../../../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-legal-cookies-js": () => import("./../../../src/pages/legal/cookies.js" /* webpackChunkName: "component---src-pages-legal-cookies-js" */),
  "component---src-pages-legal-dsar-form-js": () => import("./../../../src/pages/legal/dsar-form.js" /* webpackChunkName: "component---src-pages-legal-dsar-form-js" */),
  "component---src-pages-legal-faq-js": () => import("./../../../src/pages/legal/faq.js" /* webpackChunkName: "component---src-pages-legal-faq-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-user-agreement-js": () => import("./../../../src/pages/legal/user-agreement.js" /* webpackChunkName: "component---src-pages-legal-user-agreement-js" */),
  "component---src-pages-legal-wallet-service-terms-js": () => import("./../../../src/pages/legal/wallet-service-terms.js" /* webpackChunkName: "component---src-pages-legal-wallet-service-terms-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-wallet-js": () => import("./../../../src/pages/wallet.js" /* webpackChunkName: "component---src-pages-wallet-js" */)
}

