import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import H1 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H1';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import Counter from '../Counter';
import {
  StyledSection,
  StyledContentBlock,
  ImageWrapper,
  HeaderText,
  MediaWrapper,
} from './styled';

const Header = () => {
  const { messages } = useIntl();
  const heroImgPath = messages['home.header.image'].replace('-sm.png', '');
  const redirect = 'https://app.bitcoin.com/create/';

  return (
    <StyledSection>
      <StyledContentBlock
        className="homepage-hero"
        flip="vertical"
        aside={
          <ImageWrapper>
            <Image
              maxWidth="unset"
              maxHeight="inherit"
              className="homepage-hero-img"
              src={messages[`home.header.image`]}
              alt={messages[`home.header.image-alt`]}
              sizes="(max-width: 767px) 260w, (max-width: 1199px) 327w, (min-width: 1200px) 450w"
              srcSet={`${heroImgPath}-sm.png 260w, ${heroImgPath}-md.png 327w, ${heroImgPath}-lg.png 450w, ${heroImgPath}-sm@2x.png 520w, ${heroImgPath}-md@2x.png 655w, ${heroImgPath}-lg@2x.png 900w`}
            />
          </ImageWrapper>
        }
      >
        <HeaderText>
          <H1 className="homepage-hero-title">
            <FormattedMessage id="home.header.title" />
          </H1>
          <Paragraph>
            <FormattedMessage id="home.header.subtitle" />
          </Paragraph>
        </HeaderText>
        <Button design="primary" href={redirect} style={{ marginTop: '24px' }}>
          <FormattedMessage id="home.header.create-account-button" />
        </Button>
      </StyledContentBlock>
      <Counter />
      <MediaWrapper>
        <Image
          maxWidth="unset"
          maxHeight="unset"
          src={messages['home.header.media']}
          alt="media graphic"
          sizes="(max-width: 1199px) 994w, (min-width: 1200px) 1988w"
          srcSet="/images/uploads/homepage-media@1x.png 994w, /images/uploads/homepage-media@2x.png 1988w"
        />
      </MediaWrapper>
    </StyledSection>
  );
};

export default Header;
