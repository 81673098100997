import * as amplitude from '@amplitude/analytics-browser';
import { ampli } from '@bitcoin-portal/web-ampli';

export const initAmplitude = async () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);

    const sessionId = urlParams.get('sessionId');

    const deviceId = urlParams.get('deviceId');

    const config = {};

    if (sessionId) {
      config.sessionId = Number(sessionId);
    }
    if (deviceId) {
      config.deviceId = deviceId;
    }
    await amplitude.init(process.env.AMPLITUDE_KEY, undefined, config).promise;
    ampli.load({
      client: {
        apiKey: process.env.AMPLITUDE_KEY,
      },
    });
  } catch (e) {
    console.error('Error initialising Amplitude', e);
  }
};

export const sendAmpUserProps = (properties = {}) => {
  try {
    Object.keys(properties).forEach(k => {
      const identifyEvent = new amplitude.Identify();
      const value = properties[k];
      const stringifiedValue = JSON.stringify(value);
      identifyEvent.set(k, stringifiedValue);
      amplitude.identify(identifyEvent);
    });
  } catch (e) {
    console.error('Error identifying Amplitude user', e);
  }
};

export const sendAmpEvent = async ({ label, properties = {} }, cb = null) => {
  try {
    await amplitude.track(label, properties).promise;
    if (cb !== null) cb();
  } catch (e) {
    console.error('Error sending Amplitude event', e);
  }
};

export const sendGaEvent = ({ category = 'www', action, label }, cb = null) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      event: 'eventBus',
    });
  } else if (cb !== null) {
    cb();
  }
};
