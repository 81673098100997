import styled from 'styled-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';

export const StyledContentBlock = styled(ContentBlock)`
  ${media.md`
    margin-bottom: 100px;
  `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: visible;
  width: 100%;
  max-height: 500px;

  & > span {
    display: inline-flex !important;
    justify-content: center;
  }

  ${media.md`
    justify-content: flex-end;
    max-height: 320px;
  `}

  ${media.xl`
    max-height: 500px;
  `}
`;

export const StyledImage = styled(Image)`
  filter: drop-shadow(0px 20px 51px rgba(255, 80, 80, 0.6));
  margin-bottom: 40px;
  width: 231px;
  height: 253px;

  ${media.md`
    filter: drop-shadow(0px 28px 71px rgba(255, 80, 80, 0.6));
    margin-bottom: 0;
    width: 323px;
    height: 353px;
  `}

  ${media.xl`
    filter: drop-shadow(0px 44px 110px rgba(255, 80, 80, 0.6));
    width: 498px;
    height: 545px;
  `}
`;
