import styled from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';

export const StyledSection = styled(Section)`
  display: flex;
  flex-flow: column nowrap;
`;

export const Heading = styled.div`
  display: flex;
  flex-flow: column wrap;
  order: 2;
  padding: 0 30px;
  max-width: 600px;

  ${media.md`
    align-items: center;
    order: 1;
    padding: unset;
    margin: auto;
    margin-bottom: 80px;
  `}
`;

export const Subheading = styled(Paragraph)`
  color: ${colors.solid.textTwo};
  text-align: left;

  ${media.md`
    text-align: center;
    font-size: 18px;
    line-height: 32px;
  `}

  ${media.xl`
    font-size: 20px;
    line-height: 40px;
  `}
`;

export const StyledButton = styled(Button)`
  margin: 20px 0 30px;
  width: 100%;

  ${media.md`
    display: none;
  `}
`;

export const StyledText = styled(Paragraph)`
  color: ${colors.solid.primaryBlue};
  text-align: center;

  ${media.md`
    font-size: 18px;
    line-height: 26px;
  `}

  ${media.xl`
    font-size: 20px;
    line-height: 40px;
  `}
`;

export const StyledContentBlock = styled(ContentBlock)`
  display: flex;
  align-items: center;
  order: 1;
  width: 100%;

  & > div {
    display: flex;
    justify-content: center;
  }

  .bitcoincom-buysell-widget {
    display: none;
  }

  ${media.md`
    order: 2;
    flex-flow: row wrap;

    .bitcoincom-buysell-widget {
      display: flex;
      flex-flow: column nowrap;
    }

    & > div:last-child {
      min-height: 583px;
    }
  `}

  ${media.lg`
    flex-flow: row nowrap;
    align-content: center;
  `}
`;

export const ImageWrapper = styled.div`
  height: 318px;

  ${media.md`
    height: 400px;
  `};

  ${media.xl`
    height: 550px;
  `}
`;
