import styled from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import H4 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H4';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';

export const StyledSection = styled(Section)`
  ${media.xl`
    padding-top: 140px;
  `}
`;

export const StyledContentBlock = styled(ContentBlock)`
  ${media.md`
    flex-flow: column nowrap;

    & > div:first-child {
      order: 1;
    }

    & > div:last-child {
      order: 0;
    }
  `}

  ${media.lg`
    flex-flow: row nowrap;

    & > div:first-child {
      order: 0;
    }

    & > div:last-child {
      order: 1;
    }
  `}
`;

export const ImageWrapper = styled.div`
  height: 318px;
  display: flex;
  justify-content: center;

  span {
    display: inline-flex !important;
    justify-content: center !important;
    width: 100%;
  }

  ${media.md`
    height: 400px;

  `};

  ${media.xl`
    height: 450px;

    & > span {
      align-items: center;
    }
  `}
`;

export const StyledImage = styled(Image)`
  filter: drop-shadow(0px 20px 51px rgba(39, 147, 255, 0.6));
  margin-bottom: 40px;
  width: 231px;
  height: 269px;

  ${media.md`
    filter: drop-shadow(0px 28px 71px rgba(39, 147, 255, 0.6));
    margin-bottom: 0;
    width: 323px;
    height: 377px;
  `}

  ${media.xl`
    filter: drop-shadow(0px 44px 110px rgba(39, 147, 255, 0.6));
    width: 499px;
    height: 582px;
  `}
`;

export const Title = styled(H4)`
  margin-bottom: 8px;
  width: 100%;

  ${media.md`
    margin-bottom: 16px;
  `}

  ${media.xl`
    width: 70%;
  `}
`;

export const Subtitle = styled(Paragraph)`
  padding-bottom: 20px;
  text-align: left;
  color: ${colors.solid.textTwo};

  ${media.md`
    padding-bottom: 40px;
  `}

  ${media.md`
    padding-bottom: 40px;
  `}
`;

export const Item = styled.div`
  display: flex;
  flex-flow: row nowrap;

  svg {
    color: ${colors.solid.primaryBlue};
  }
`;
export const TextWrapper = styled.div`
  margin: 0 0 32px 30px;
  font-size: 18px;
  width: 95%;

  & div:first-child {
    font-weight: 700;
    line-height: 26px;
  }

  & div:last-child {
    color: ${colors.solid.textTwo};
    line-height: 32px;
  }
`;

export const ListWrapper = styled.div`
  display: none;

  ${media.md`
    display: flex;
    flex-flow: column nowrap;

    & > div:last-child > ${TextWrapper} {
      margin-bottom: 0;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  a:first-child {
    margin-bottom: 24px;
  }

  ${media.md`
    margin-top: 48px;
    margin-bottom: 0;
    flex-flow: row nowrap;

    a:first-child {
      margin-right: 24px;
    }
  `}
`;
