import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Tabs from '@bitcoin-portal/bitcoincom-pkg-components/dist/Tabs';
import { TabsWrapper } from './styled';

const Menu = ({ selectedTab, netlify, menuTabs }) => {
  const menu = netlify || menuTabs || [];

  const menuItems = menu.map(item => {
    return {
      label: item.title,
      action: () => {
        navigate(item.link);
      },
    };
  });
  return (
    <TabsWrapper>
      <Tabs tabs={menuItems} selectedTab={selectedTab} />
    </TabsWrapper>
  );
};

Menu.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  netlify: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  menuTabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};

Menu.defaultProps = {
  netlify: undefined,
  menuTabs: undefined,
};

export default Menu;
