import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import H2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H2';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Loader from '@bitcoin-portal/bitcoincom-pkg-components/dist/Loader';
import {
  Content,
  ListCtn,
  Postings,
  Dept,
  PostTitle,
  PostingsLink,
} from './styled';

const Listings = () => {
  const [jobItems, setJobItems] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const { data } = await axios(
          'https://activecampaign.prod.cloud.bitcoin.com/jobs',
        );
        const items = await data.jobs.reduce(
          (prev, curr) => [
            ...prev,
            {
              jobtitle: curr.title,
              idurl: curr.url,
              location: curr.location.country,
              team: curr.department,
              positiontype: curr.employment_type,
            },
          ],
          [],
        );
        setJobItems(items);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    };

    fetchJobs();
  }, []);

  return (
    <Section>
      <ContentBlock id="joblist">
        <H2 align="center">
          <FormattedMessage id="jobs.listings.title" />
        </H2>
        {jobItems.length > 0 && (
          <Content>
            {jobItems.map(
              ({ team, jobtitle, positiontype, idurl, location }) => (
                <ListCtn key={idurl}>
                  <PostingsLink href={idurl} target="_blank" rel="noreferrer">
                    <Postings>
                      <PostTitle>{jobtitle}</PostTitle>
                      <Dept>
                        {team}, {location}, {positiontype}
                      </Dept>
                    </Postings>
                  </PostingsLink>
                </ListCtn>
              ),
            )}
          </Content>
        )}

        {loading && (
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Loader />
          </div>
        )}

        {!loading && jobItems.length === 0 && (
          <Paragraph align="center">
            <FormattedMessage id="jobs.listings.empty" />
          </Paragraph>
        )}
      </ContentBlock>
    </Section>
  );
};

export default Listings;
