import styled from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column nowrap;
  text-align: left;

  ${media.md`
    flex-flow: row nowrap;
  `}
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${colors.solid.textTwo};
  font-size: 16px;
  line-height: 28px;

  ${media.md`
    font-size: 18px;
    line-height: 26px;
    width: 70%;
  `}

  ${media.xl`
    font-size: 20px;
    line-height: 40px;
    width: 100%;
  `}
`;

export const StyledButton = styled(Button)`
  display: none;

  ${media.md`
    display: flex;
    margin-bottom: 40px;
  `}
`;

export const StyledMobileButton = styled(Button)`
  margin-top: 40px;
  width: 100%;

  ${media.md`
    display: none;
  `}
`;

export const CardContainer = styled.div`
  display: grid;
  grid-column-gap: 56px;
  position: relative;
  grid-template-columns: 1fr;a
  order: 2;

  ${media.md`
   grid-template-columns: repeat(3, 1fr);
  `}
`;

export const Card = styled.a`
  display: flex;

  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  background: white;
  position: relative;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.default};
  width: 100%;
  flex-basis: 30%;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  ${media.md`
    align-items: flex-start;
    flex-direction: column;
  `}

  & > ::after {
    position: absolute;
    content: '';
    border-radius: 3px;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
  }
`;

export const CardImage = styled(Image)`
  border-radius: 3px;
  width: 120px;
  height: ${({ theme }) => theme.spacing.unit * 10}px;
  order: 2;
  object-fit: cover;
  margin-left: ${({ theme }) => theme.spacing.unit * 3}px;

  ${media.md`
    order: -1;
    margin-left: unset;
    object-fit: contain;
    height: unset;
    width: 100%;
    order: 0;
  `}
`;

export const CardTitle = styled.h4`
  color: ${({ theme }) => theme.palette.text.default};
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  order: -1;
  margin: 0;

  ${media.md`
    order: 0;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 8px;
    margin-top: 16px;
  `}

  ${media.xl`
    font-size: 24px;
    line-height: 32px;
  `}
`;

export const CardText = styled(Paragraph)`
  color: ${colors.solid.textTwo};
  display: none;

  ${media.md`
    display: flex;
    font-size: 14px;
    line-height: 24px;
  `};

  ${media.xl`
    font-size: 16px;
    line-height: 28px;
  `}
`;

export const CardContent = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: ${colors.solid.bgGrey};

  order: 0;

  ${media.md`
    order: 1;
    flex-direction: column;
  `}
`;
