import React from 'react';
import { useIntl } from 'react-intl';

import Navbar from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';

import SEO from '../../components/SEO';
import Header from './Header';
import Who from './Who';
import Listings from './Listings';
import { appLocales } from '../../i18n';

const Jobs = () => {
  const { messages, locale } = useIntl();

  return (
    <>
      <SEO page="jobs" />
      <Navbar
        locale={locale}
        buttons={messages['global.nav.buttons']}
        links={messages['global.nav.links']}
      />
      <Header />
      <Who />
      <Listings />
      <Footer
        locale={locale}
        localeList={Object.keys(appLocales)}
        cookieName="bitcoinjp-locale"
        legal={messages['global.footer.legal']}
        links={messages['global.footer.links']}
        defaultLocale="ja"
      />
    </>
  );
};

export default Jobs;
