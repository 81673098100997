import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import Jobs from '../views/Jobs';

const JobsPage = props => {
  const {
    pageContext: { locale },
    location: { pathname },
  } = props;

  return (
    <Layout locale={locale} localeDir={pathname}>
      <Jobs />
    </Layout>
  );
};

JobsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default JobsPage;
