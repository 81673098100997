import React from 'react';
import { useIntl } from 'react-intl';

import Navbar from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';

import SEO from './SEO';
import Header from './Header';
import Sites from './Sites';
import { Wrapper } from './styled';
import { appLocales } from '../../i18n';

const Sitemap = () => {
  const { messages, locale } = useIntl();
  return (
    <>
      <SEO />
      <Wrapper>
        <Navbar
          locale={locale}
          buttons={messages['global.nav.buttons']}
          links={messages['global.nav.links']}
        />
        <Header />
        <Sites />
        <Footer
          locale={locale}
          localeList={Object.keys(appLocales)}
          cookieName="bitcoinjp-locale"
          legal={messages['global.footer.legal']}
          links={messages['global.footer.links']}
          defaultLocale="ja"
        />
      </Wrapper>
    </>
  );
};

export default Sitemap;
