import styled, { css } from 'styled-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const Apps = styled.div`
  ${({ theme, center }) => css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    ${media.md`
    margin: ${theme.spacing.unit * 4}px 0 !important;
    flex-wrap: unset;
  `}

    ${!center &&
    media.md`
      justify-content: flex-start;
    `}

  & > a {
      max-width: 130px;
      margin: ${theme.spacing.unit}px;
      min-height: 42px;
      ${media.md`
        max-width: 200px;
        margin: 0;
        margin-right: 20px;
      `}
    }

    & > a > img {
      height: auto;
    }
  `}
`;
