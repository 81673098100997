import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import CheckCircle from '@bitcoin-portal/bitcoincom-pkg-components/dist/IconsNavigation/CheckCircle';
import {
  StyledSection,
  StyledContentBlock,
  ImageWrapper,
  StyledImage,
  Title,
  Subtitle,
  Item,
  ListWrapper,
  TextWrapper,
  ButtonWrapper,
} from './styled';

const GetStarted = () => {
  const { messages } = useIntl();
  const imgPath = messages['home.get-started.image'].replace('-sm.png', '');

  return (
    <StyledSection>
      <StyledContentBlock
        flip="vertical"
        aside={
          <ImageWrapper>
            <StyledImage
              maxWidth="unset"
              maxHeight="inherit"
              style={{ overflow: 'visible' }}
              src={messages['home.get-started.image']}
              alt={messages['home.get-started.image-alt']}
              sizes="(max-width: 767px) 231w, (max-width: 1199px) 323w, (min-width: 1200px) 499w"
              srcSet={`${imgPath}-sm.png 231w, ${imgPath}-md.png 323w, ${imgPath}-sm@2x.png 462w, ${imgPath}-lg.png 499w, ${imgPath}-md@2x.png 646w, ${imgPath}-lg@2x.png 900w`}
            />
          </ImageWrapper>
        }
      >
        <Title>
          <FormattedMessage id="home.get-started.title" />
        </Title>
        <Subtitle>
          <FormattedMessage id="home.get-started.subtitle" />
        </Subtitle>
        <ListWrapper>
          {messages['home.get-started.checklist'].map(
            ({ heading, subheading }) => (
              <Item key={heading}>
                <CheckCircle height={34} />
                <TextWrapper>
                  <div>{heading}</div>
                  <div>{subheading}</div>
                </TextWrapper>
              </Item>
            ),
          )}
        </ListWrapper>
        <ButtonWrapper>
          <Button
            design="primary"
            href={messages['home.get-started.start-link']}
          >
            <span style={{ display: 'flex' }}>
              {messages['home.get-started.start']}{' '}
              <CheckCircle
                height={24}
                color="white"
                style={{ alignSelf: 'center', marginLeft: '11px' }}
              />
            </span>
          </Button>
        </ButtonWrapper>
      </StyledContentBlock>
    </StyledSection>
  );
};

export default GetStarted;
