import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import structuredJSON from './structuredData.json';

const SEO = () => {
  const intl = useIntl();
  return (
    <Helmet title={intl.formatMessage({ id: 'home.meta.title' })}>
      <meta
        name="description"
        content={intl.formatMessage({
          id: 'home.meta.description',
        })}
      />
      <meta
        property="og:title"
        content={intl.formatMessage({ id: 'home.meta.title' })}
      />
      <meta
        property="og:description"
        content={intl.formatMessage({
          id: 'home.meta.description',
        })}
      />
      <meta
        property="og:image"
        content={`https://www.bitcoin.jp${intl.messages['home.meta.image']}`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:site"
        content={`@${intl.formatMessage({ id: 'home.meta.twitter' })}`}
      />
      <meta
        name="twitter:title"
        content={intl.formatMessage({ id: 'home.meta.title' })}
      />
      <meta
        name="twitter:description"
        content={intl.formatMessage({
          id: 'home.meta.description',
        })}
      />
      <meta
        name="twitter:image"
        content={`https://www.bitcoin.jp${intl.messages['home.meta.image']}`}
      />
      <script type="application/ld+json">
        {JSON.stringify(structuredJSON)}
      </script>
      <link rel="canonical" href="https://www.bitcoin.jp/" />
    </Helmet>
  );
};

export default SEO;
