import styled from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.unit * 8}px;
`;

export const FormWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.unit * 2}px;

  ${media.md`
    padding: 0;
  `};
`;

export const CustomContentBlock = styled.div`
  margin: 40px 0 0;
`;

export const AdDesktop = styled.div`
  display: none;
  width: 100%;
  text-align: center;
  ${media.md`
    display: block;
  `};
`;

export const AdMobile = styled.div`
  width: 100%;
  text-align: center;
  display: block;
  ${media.md`
    display: none;
  `};
`;

export const Heading = styled.div`
  margin: auto;
  padding: 10px 0;
  text-align: center;

  p {
    margin: auto;
    color: ${colors.solid.textTwo};
  }

  ${media.md`
    padding: 30px 0;
  `}
`;

export const StyledCard = styled.div`
  display: flex;
  padding: 0;
  flex: 1;
  max-width: 280px;
  min-width: 200px;
  flex-direction: column;

  & > div {
    display: flex;
    height: 100%;
    padding-bottom: 10px;
  }

  ${media.md`
    padding: 0 16px;

    & > div {
      flex-direction: column;
    }

  `}

  ${media.lg`
    margin: auto;
  `}
`;

export const StyledImage = styled(Image)`
  max-height: 25px;
  max-width: 25px;
  margin-right: 25px;

  ${media.md`
    padding-bottom: 24px;
    max-height: 50px;
    max-width: 50px;
  `}
`;

export const CardTitle = styled(Paragraph)`
  ${media.md`
    font-size: 16px;
    line-height: 20px;
  `}
  padding-bottom: 0;
`;

export const CardText = styled(Paragraph)`
  ${media.md`
    font-size: 14px;
    line-height: 24px;
    min-height: 100px;
  `}
`;
