import styled from 'styled-components';
import { colors, media } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Img from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';

export const TableHeader = styled.div`
  & > div:not(div:last-child, div:first-child) {
    width: 140px;
  }

  & > div:last-child {
    margin-left: 60px;
  }

  ${media.md`
    & > div:not(div:last-child, div:first-child) {
      width: 200px;
    }

    & > div:last-child {
      margin-left: 60px;
    }
  `}

  ${media.lg`
    & > div:not(div:last-child, div:first-child) {
      width: 150px;
    }
  `}
`;

export const NameWrapper = styled.div`
  text-indent: 24px;
  position: sticky;
  left: 0px;
  background-color: white;
  width: 180px;

  ${media.md`
    width: 250px;
  `}

  ${media.lg`
    width: 200px;
  `}

  ${media.xl`
    width: 450px;
  `}
`;

export const RowHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  left: 0px;
  background-color: white;
  z-index: 1;
  width: 180px;

  ${media.md`
    width: 250px;
  `}

  ${media.lg`
    width: 200px;
  `}

  ${media.xl`
    width: 450px;
  `}
`;

export const Image = styled(Img)`
  width: 48px;
  padding: 20px 0;
  padding-left: 16px;
  background-color: ${colors.solid.bgWhite};
  z-index: 100;
  margin-right: 16px;
`;

export const Label = styled.div`
  color: ${colors.solid.textOne};
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-right: 16px;
  display: flex;
  flex-flow: row wrap;
  max-width: 100px;

  ${media.md`
    font-size: 16px;
    line-height: 28px;
  `}

  ${media.xl`
    min-width: fit-content;
    max-width: unset;
    font-size: 20px;
    line-height: 40px;
  `}
`;

export const Ticker = styled.div`
  color: ${colors.solid.textThree};
  font-size: 14px;
  line-height: 16px;

  ${media.md`
    font-size: 18px;
    line-height: 32px;
  `}
`;

export const Price = styled.div`
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 0 10px;
  width: 140px;

  ${media.md`
    width: 200px;
    font-size: 16px;
    line-height: 28px;
  `}

  ${media.lg`
    width: 150px;
  `}

  ${media.xl`
    font-size: 20px;
    line-height: 40px;
  `}
`;

export const ChartArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 140px;

  & > div {
    max-width: 130px;
    cursor: pointer !important;
  }

  ${media.md`
    width: 200px;
  `}

  ${media.lg`
    width: 150px;
  `}
`;

export const Change = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 140px;
  color: ${({ positive }) =>
    positive ? colors.solid.primaryGreen : colors.solid.primaryRed};

  ${media.md`
    font-size: 16px;
    line-height: 28px;
    width: 180px;
  `}

  ${media.lg`
    width: 150px;
  `}

  ${media.xl`
    font-size: 20px;
    line-height: 40px;
  `}
`;

export const LoadWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
