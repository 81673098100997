import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Axios from 'axios';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import Loader from '@bitcoin-portal/bitcoincom-pkg-components/dist/Loader';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Table from '@bitcoin-portal/bitcoincom-pkg-components/dist/Table';
import {
  LoadWrapper,
  TableHeader,
  RowHeader,
  NameWrapper,
  Image,
  Label,
  Price,
  Ticker,
  ChartArea,
  Change,
} from './styled';
import Chart from './Chart';

const CryptoList = ({ openModal, cryptos }) => {
  const [graphData, setHistory] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getHistory = async () => {
      try {
        setLoading(true);

        const tickers = cryptos
          .reduce((p, { code }) => [...p, code], [])
          .join(',');
        const apiUrl = `https://markets.api.bitcoin.com/ohlc/hourly?symbols=${tickers}`;
        const changeUrl = `https://markets.api.bitcoin.com/coin/data/ticker`;

        const {
          data: { data },
        } = await Axios.get(apiUrl);

        const {
          data: {
            data: { byId },
          },
        } = await Axios.get(changeUrl);

        const reducedData = Object.keys(data).reduce((p, c) => {
          const {
            USD: { history },
          } = data[c];
          if (!byId[c]) return p;
          return {
            ...p,
            [c]: {
              price: byId[c].priceUsd.toFixed(2),
              change: byId[c].percentChange24h.toFixed(2),
              history: history.reverse(),
              marketCapUsd: byId[c].marketCapUsd,
            },
          };
        }, {});
        setHistory(reducedData);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getHistory();
  }, [cryptos]);

  return (
    <Section>
      <ContentBlock>
        <div style={{ overflowY: 'auto', borderRadius: 24 }}>
          {loading && (
            <LoadWrapper>
              <Loader />
            </LoadWrapper>
          )}
          {!loading && (
            <Table columns={5} style={{ borderRadius: 0 }}>
              <TableHeader>
                <NameWrapper>
                  <FormattedMessage id="buy.chart.name" />
                </NameWrapper>
                <div>
                  <FormattedMessage id="buy.chart.price" />
                </div>
                <div>
                  <FormattedMessage id="buy.chart.change" />
                </div>
                <div>
                  <FormattedMessage id="buy.chart.chart" />
                </div>
                <div>
                  <FormattedMessage id="buy.chart.buy" />
                </div>
              </TableHeader>
              {graphData &&
                cryptos
                  .sort((a, b) => {
                    const aData = graphData[a.code];
                    const bData = graphData[b.code];
                    if (['BTC', 'BCH'].includes(b.code)) {
                      return 1;
                    }
                    if (
                      aData &&
                      bData &&
                      bData.marketCapUsd > aData.marketCapUsd
                    ) {
                      return 1;
                    }
                    return -1;
                  })
                  .map(({ code, providers, label, image }) => {
                    if (!graphData[code]) return null;
                    const { provider } = providers[0];
                    return (
                      <div key={code} style={{ padding: '20px' }}>
                        <RowHeader>
                          <Image
                            height={32}
                            width={32}
                            src={image}
                            alt={code}
                          />
                          <div>
                            <Label>{label}</Label>
                            <Ticker>{code}</Ticker>
                          </div>
                        </RowHeader>

                        <Price>
                          {new Intl.NumberFormat('en', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(graphData[code].price)}
                        </Price>

                        <Change positive={graphData[code].change > 0}>
                          {graphData[code].change}%
                        </Change>

                        <ChartArea>
                          <Chart graphData={graphData[code].history} />
                        </ChartArea>

                        <Button
                          style={{ width: '80px', marginLeft: 40 }}
                          design="primary"
                          size={Button.Sizes.Small}
                          onClick={() => {
                            openModal(code, provider);
                          }}
                        >
                          <FormattedMessage id="buy.cryptocurrencies.button-text" />
                        </Button>
                      </div>
                    );
                  })}
            </Table>
          )}
        </div>
      </ContentBlock>
    </Section>
  );
};

CryptoList.propTypes = {
  openModal: PropTypes.func,
  cryptos: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string }))
    .isRequired,
};

CryptoList.defaultProps = {
  openModal: undefined,
};

export default CryptoList;
