/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import FlexContainer from '@bitcoin-portal/bitcoincom-pkg-components/dist/FlexContainer';
import H4 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H4';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Link from '@bitcoin-portal/bitcoincom-pkg-components/dist/Link';
import BuyModal from '@bitcoin-portal/bitcoincom-pkg-components/dist/BuyModal';
import {
  Heading,
  StyledCard,
  StyledImage,
  CardTitle,
  CardText,
} from '../styled';

const QuickGuide = () => {
  const { messages } = useIntl();
  const ref = useRef(null);

  const steps = messages['buy.quick-guide.steps'] || [];

  return (
    <Section>
      <ContentBlock>
        <Heading>
          <H4 align="center">
            <FormattedMessage id="buy.quick-guide.title" />
          </H4>
          <Paragraph>
            <FormattedMessage id="buy.quick-guide.subtitle" />
          </Paragraph>
        </Heading>
        <FlexContainer
          style={{
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginTop: 24,
          }}
        >
          <StyledCard key={messages['buy.quick-guide.first-step.title']}>
            <div>
              <StyledImage
                maxWidth={50}
                maxHeight={50}
                src={messages['buy.quick-guide.first-step.image']}
              />
              <CardTitle bold>
                {messages['buy.quick-guide.first-step.title']}
              </CardTitle>
            </div>
            <CardText>
              <FormattedMessage
                id="buy.quick-guide.first-step.description"
                values={{
                  BUY: <Link onClick={() => ref.current.open()}>Buy</Link>,
                  Comprar: (
                    <Link onClick={() => ref.current.open()}>Comprar</Link>
                  ),
                  Купить: (
                    <Link onClick={() => ref.current.open()}>Купить</Link>
                  ),
                  购买: <Link onClick={() => ref.current.open()}>购买</Link>,
                }}
              />
            </CardText>
          </StyledCard>
          {steps.map(({ title, description, image }) => {
            return (
              <StyledCard key={title}>
                <div>
                  <StyledImage src={image} maxWidth={50} maxHeight={50} />
                  <CardTitle bold>{title}</CardTitle>
                </div>
                <CardText>{description}</CardText>
              </StyledCard>
            );
          })}
        </FlexContainer>
      </ContentBlock>
      <BuyModal modalRef={ref} />
    </Section>
  );
};

export default QuickGuide;
