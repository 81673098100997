import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import { useIntl, FormattedMessage } from 'react-intl';
import capitalize from 'lodash/capitalize';
import H1 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H1';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import ComboWidget from '@bitcoin-portal/bitcoincom-pkg-components/dist/ComboWidget';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import {
  StyledContentBlock,
  Heading,
  ImageWrapper,
  MediaWrapper,
} from './styled';

import {
  sendAmpEvent,
  sendAmpUserProps,
  sendGaEvent,
} from '../../../helpers/analytics';

// const VARIANT_IDS = {
//   1: '2105_fomo1',
//   2: '2105_fomo2'
// };

const Header = ({ provider, crypto, setCryptos }) => {
  const { messages, locale } = useIntl();
  const [providerTracked, setProviderTracked] = useState(false);

  const imgPath = messages['buy.header.image'].replace('-sm.png', '');

  return (
    <Section>
      {/* {(variant === "0") && ( */}
      <Heading>
        <H1>
          <FormattedMessage id="buy.header.title" />
        </H1>
        <Paragraph>
          <FormattedMessage id="buy.header.subtitle" />
        </Paragraph>
      </Heading>
      {/* )} */}
      <StyledContentBlock
        aside={
          <ComboWidget
            small
            provider={provider}
            baseCurrencyAmt={200}
            crypto={crypto ? crypto.toUpperCase() : undefined}
            buyLocales={{
              buyTab: messages['buy.buy.tab'],
              buy: messages['buy.buy.buy'],
              spend: messages['buy.buy.spend'],
              amount: messages['buy.buy.amount'],
              button: messages['buy.buy.button'],
              exit: messages['buy.buy.exit'],
              min: messages['buy.buy.min'],
              max: messages['buy.buy.max'],
              locale: locale !== 'en' ? locale : undefined,
            }}
            sellLocales={{
              sellTab: messages['buy.sell.tab'],
              buy: messages['buy.sell.buy'],
              spend: messages['buy.sell.spend'],
              amount: messages['buy.sell.amount'],
              button: messages['buy.sell.button'],
              exit: messages['buy.sell.exit'],
              min: messages['buy.sell.min'],
              max: messages['buy.sell.max'],
              locale: locale !== 'en' ? locale : undefined,
              disclaimer: messages['buy.sell.disclaimer'],
            }}
            externalTransactionId="bitcoinjp"
            clickFn={({ action, crypto: clickedCrypto, sell }) => {
              if (action === 'open') {
                if (!sell) {
                  sendAmpEvent({
                    label: 'buy_button_clicked',
                    properties: {
                      coin: clickedCrypto,
                      pageview: window.location.href,
                    },
                  });
                }
                sendGaEvent({
                  category: 'Combo widget',
                  action: sell ? 'Sell' : 'Buy',
                  label: clickedCrypto,
                });
              }
            }}
            cryptosFn={currencies => {
              const sortedCurrencies = currencies.sort((a, b) => {
                const textA = a.code.toUpperCase();
                const textB = b.code.toUpperCase();
                if (['BTC', 'BCH'].includes(a.code)) return -1;
                if (textA < textB) return -1;
                if (textA > textB) return 1;
                return 0;
              });
              setCryptos(sortedCurrencies);
            }}
            providerFn={(p, sell) => {
              if (providerTracked === true) return;
              sendAmpUserProps({
                provider: p,
                widget: 'vertical',
              });
              sendGaEvent({
                category: 'Combo Widget',
                action: sell ? 'Load sell' : 'Load buy',
                label: capitalize(p),
              });
              setProviderTracked(true);
            }}
          />
        }
      >
        <ImageWrapper>
          <Image
            maxWidth="unset"
            maxHeight="inherit"
            width="unset"
            src={messages['buy.header.image']}
            alt={messages['buy.header.alt']}
            sizes="(max-width: 767px) 260w, (max-width: 1199px) 327w, (min-width: 1200px) 450w"
            srcSet={`${imgPath}-sm.png 260w, ${imgPath}-md.png 327w, , ${imgPath}-lg.png 450w, ${imgPath}-sm@2x.png 520w, ${imgPath}-md@2x.png 655w, ${imgPath}-lg@2x.png 900w`}
          />
        </ImageWrapper>
      </StyledContentBlock>
      <MediaWrapper>
        <Image
          maxWidth="unset"
          maxHeight="unset"
          src={messages['home.header.media']}
          alt="media graphic"
          sizes="(max-width: 1199px) 994w, (min-width: 1200px) 1988w"
          srcSet="/images/uploads/homepage-media@1x.png 994w, /images/uploads/homepage-media@2x.png 1988w"
        />
      </MediaWrapper>
    </Section>
  );
};
Header.propTypes = {
  provider: PropTypes.string,
  crypto: PropTypes.string,
  setCryptos: PropTypes.func.isRequired,
};

Header.defaultProps = {
  provider: undefined,
  crypto: undefined,
};

export default Header;
