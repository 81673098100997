import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Axios from 'axios';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Loader from '@bitcoin-portal/bitcoincom-pkg-components/dist/Loader';
import H5 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H5';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import ChartBar from '@bitcoin-portal/bitcoincom-pkg-components/dist/IconsNavigation/ChartBar';
import Chart from './Chart/Chart';
import {
  SectionHeader,
  StyledParagraph,
  StyledGrid,
  StyledCard,
  ChartWrapper,
  Ticker,
  Label,
  Header,
  ChartArea,
  PriceArea,
  Price,
  Change,
  StyledButton,
} from './styled';

class Markets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graphData: {
        BCH: { price: 0, history: [], change: '--' },
        BTC: { price: 0, history: [], change: '--' },
        ETH: { price: 0, history: [], change: '--' },
        LTC: { price: 0, history: [], change: '--' },
      },
    };

    this.getMarketData = this.getMarketData.bind(this);
  }

  async componentDidMount() {
    await this.getMarketData();
  }

  async getMarketData() {
    const {
      intl: { messages },
    } = this.props;

    console.log('getting market data');
    const tickers = messages['home.markets.list']
      .reduce((p, c) => [...p, c.ticker], [])
      .join(',');

    const apiUrl = `https://markets.api.bitcoin.com/ohlc/hourly?symbols=${tickers}`;
    const changeUrl = `https://markets.api.bitcoin.com/coin/data/ticker`;

    const {
      data: { data },
    } = await Axios.get(apiUrl);

    const {
      data: {
        data: { byId },
      },
    } = await Axios.get(changeUrl);

    const graphData = Object.keys(data).reduce((p, c) => {
      const {
        USD: { history },
      } = data[c];

      return {
        ...p,
        [c]: {
          price: byId[c].priceUsd.toFixed(2),
          change: byId[c].percentChange24h.toFixed(2),
          history: history.reverse(),
        },
      };
    }, {});

    this.setState({
      graphData,
    });

    setTimeout(() => {
      this.getMarketData();
    }, 1000 * 60);
  }

  render() {
    const {
      intl: { messages },
    } = this.props;
    const { graphData: gd } = this.state;

    const items = messages['home.markets.list'];

    return (
      <Section>
        <ContentBlock>
          <SectionHeader>
            <div>
              <H5>{messages['home.markets.title']}</H5>
              <StyledParagraph>
                {messages['home.markets.subtitle']}
              </StyledParagraph>
            </div>
            <Button href={messages['home.markets.link']} design="secondary">
              {messages['home.markets.more']}
            </Button>
          </SectionHeader>
          <StyledGrid columns={4} columnsMd={3} columnsSm={1}>
            {items.map(({ ticker, link, image, label }, index) => {
              const { price, change, history } = gd[ticker];

              if (history.length < 1)
                return (
                  <div
                    key={ticker}
                    style={{ height: 49, width: 134, textAlign: 'center' }}
                  >
                    <Loader />
                  </div>
                );

              return (
                <StyledCard key={ticker} href={link} newTab index={index}>
                  <ChartWrapper>
                    <Header>
                      <Image
                        height={32}
                        width={32}
                        src={image}
                        alt={ticker}
                        wrapperProps={{ style: { marginRight: 8 } }}
                      />
                      <div>
                        <Label>{label}</Label>
                        <Ticker>{ticker}</Ticker>
                      </div>
                    </Header>
                    <ChartArea>
                      <Chart graphData={history} />
                    </ChartArea>
                    <PriceArea>
                      <Price>
                        {new Intl.NumberFormat('en', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(price)}
                      </Price>
                      <Change positive={change > 0}>{change}%</Change>
                    </PriceArea>
                  </ChartWrapper>
                </StyledCard>
              );
            })}
            <StyledCard id="mobile-markets">
              <ChartWrapper>
                <StyledButton
                  fullWidth
                  design="primary"
                  href={messages['home.markets.link']}
                >
                  {messages['home.markets.mobile-more']}
                  <ChartBar
                    height={24}
                    style={{ alignSelf: 'center', marginLeft: '11px' }}
                  />
                </StyledButton>
              </ChartWrapper>
            </StyledCard>
          </StyledGrid>
        </ContentBlock>
      </Section>
    );
  }
}

Markets.propTypes = {
  intl: PropTypes.shape({
    messages: PropTypes.object,
  }).isRequired,
};

export default injectIntl(Markets);
