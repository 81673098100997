import React from 'react';
import { FormattedMessage } from 'react-intl';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import H1 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H1';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import { useTheme } from 'styled-components';

const Header = () => {
  const theme = useTheme();
  return (
    <>
      <Section>
        <ContentBlock style={{ textAlign: 'center' }}>
          <Paragraph
            style={{
              ...theme.palette.primary.text,
              textTransform: 'uppercase',
            }}
          >
            <FormattedMessage id="jobs.header.overtext" />
          </Paragraph>
          <H1>
            <FormattedMessage id="jobs.header.title" />
          </H1>
          <Button design={Button.Designs.Primary} href="#joblist">
            <FormattedMessage id="jobs.header.btn1" />
          </Button>
        </ContentBlock>
      </Section>
    </>
  );
};

export default Header;
