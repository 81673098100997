import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import fetch from 'cross-fetch';
import { parseString } from 'xml2js';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import H5 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H5';
import {
  SectionHeader,
  StyledParagraph,
  StyledButton,
  StyledMobileButton,
  Card,
  CardContainer,
  CardImage,
  CardTitle,
  CardText,
  CardContent,
} from './styled';

const News = () => {
  const { messages } = useIntl();
  const [newsItems, setNewsItems] = useState([]);

  const parseData = xml => {
    let returnData = [];
    parseString(xml, (err, res) => {
      const data = res.rss.channel[0].item;
      returnData = data;
    });
    returnData = returnData || [];
    return returnData;
  };

  useEffect(() => {
    async function fetchNews() {
      try {
        const NEWS_URL = 'https://news.bitcoin.com/feed/';
        const response = await fetch(NEWS_URL);
        const xml = await response.text();
        const news = parseData(xml);
        const items = news.splice(0, 3).map(item => {
          return {
            title: item.title[0],
            image: item['post-thumbnail'][0].url[0],
            link: item.link[0],
            description: item.barker_title[0],
          };
        });
        setNewsItems(items);
      } catch (error) {
        console.log(error);
      }
    }
    fetchNews();
  }, []);

  return (
    <Section>
      <ContentBlock>
        <SectionHeader>
          <div>
            <H5>{messages['home.news.title']}</H5>
            <StyledParagraph>{messages['home.news.subtitle']}</StyledParagraph>
          </div>
          <StyledButton href="https://news.bitcoin.com/" design="secondary">
            {messages['home.news.more']}
          </StyledButton>
        </SectionHeader>
        <CardContainer>
          {newsItems.map(({ title, image, link, description }, index) => (
            <Card
              key={title}
              href={link}
              target="_blank"
              index={index}
              rel="noreferrer noopenner"
            >
              <CardContent>
                <CardImage src={image} alt={title} />
                <CardTitle>{title}</CardTitle>
                <CardText>{description}</CardText>
              </CardContent>
            </Card>
          ))}
        </CardContainer>
        <StyledMobileButton href="https://news.bitcoin.com/" design="primary">
          {messages['home.news.more']}
        </StyledMobileButton>
      </ContentBlock>
    </Section>
  );
};

export default News;
