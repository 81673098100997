import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import H2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H2';
import Iframe from '@bitcoin-portal/bitcoincom-pkg-components/dist/Iframe';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';

const StyledText = styled.div`
  padding-top: 20px;

  ${media.md`
    padding-top: unset;
    padding-right: 20px;
  `}
`;

const Meet = () => {
  const { messages } = useIntl();
  return (
    <Section>
      <ContentBlock
        flip={ContentBlock.Flips.Vertical}
        style={{ alignItems: 'center' }}
        aside={
          <Iframe
            src={messages['about-us.meet.video']}
            title={messages['about-us.meet.video-title']}
          />
        }
      >
        <StyledText>
          <H2>{messages['about-us.meet.title']}</H2>
          <Paragraph>
            <FormattedMessage
              id="about-us.meet.description"
              values={{
                // eslint-disable-next-line react/no-unstable-nested-components
                EM: (...chunks) => <em>{chunks}</em>,
              }}
            />
          </Paragraph>
        </StyledText>
      </ContentBlock>
    </Section>
  );
};

export default Meet;
