import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Navbar from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';
import { HeadSection } from './styled';
import Header from './Header';
import Buy from './Buy';
import News from './News';
import SEO from './SEO';
import Markets from './Markets';
import GetStarted from './GetStarted';
import { appLocales } from '../../i18n';

const SIMPLEX_UNSUPPORTED_COUNTRIES = [
  'BS', // Bahamas
  'BW', // Botswana
  'CD', // Democratic Republic of the Congo
  'CG', // Republic of the Congo
  'KH', // Cambodia
  'KP', // Democratic People's Republic of Korea
  'ET', // Ethiopia
  'GH', // Ghana
  'IR', // Iran
  'PK', // Pakistan
  'PA', // Panama
  'LK', // Sri Lanka
  'SY', // Syria
  'TT', // Trinidad and Tobago
  'TN', // Tunisia
  'YE', // Yemen
];

const SIMPLEX_UNSUPPORTED_STATES = [
  'AK', // Alaska
  'HI', // Hawaii
  'WA', // Washington
  'NM', // New Mexico
  'VT', // Vermont
  'FL', // Florida
  'OR', // Oregon
  'AL', // Alabama
  // NY (supported for BTC)
];

const RESTRICTED_OFAC = [
  'AL',
  'BA',
  'XK',
  'BY',
  'MM',
  'CI',
  'CU',
  'CD',
  'IR',
  'IQ',
  'LR',
  'KP',
  'SD',
  'SY',
  'ZW',
];

const Home = () => {
  const { messages, locale } = useIntl();
  const [buySupported, setBuySupported] = useState(true);

  useEffect(() => {
    const getBuySupported = async () => {
      try {
        const moonpayUrl =
          'https://api.moonpay.com/v3/ip_address?apiKey=pk_live_k6WSd0AaHVEXPV4WlBBrsvRMrAhRH';

        const Axios = await import('axios');
        const { data } = await Axios.get(moonpayUrl);
        const { isAllowed, state, alpha2 } = await data;

        // setLocation(alpha2);

        if (!isAllowed) {
          if (
            SIMPLEX_UNSUPPORTED_COUNTRIES.includes(alpha2) ||
            SIMPLEX_UNSUPPORTED_STATES.includes(state) ||
            RESTRICTED_OFAC.includes(alpha2) ||
            RESTRICTED_OFAC.includes(state)
          ) {
            setBuySupported(false);
          }
        }
      } catch (error) {
        // console.log(error);
      }
    };

    getBuySupported();
  }, []);

  return (
    <>
      <SEO />
      <HeadSection>
        <Navbar
          locale={locale}
          buttons={messages['global.nav.buttons']}
          links={messages['global.nav.links']}
        />
        <Header locale={locale} />
      </HeadSection>
      {buySupported && <Buy locale={locale} />}
      <GetStarted locale={locale} />
      <Markets />
      <News />
      <Footer
        locale={locale}
        localeList={Object.keys(appLocales)}
        cookieName="bitcoinjp-locale"
        defaultLocale="ja"
      />
    </>
  );
};

export default Home;
