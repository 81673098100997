import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import H1 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H1';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';

import DownloadLinks from '../DownloadLinks';

const StyledSection = styled(Section)`
  ${media.md`
    padding-top: 40px;

    p {
      font-size: 14px;
      line-height: 20px;
    }
  `}

  ${media.lg`
    p {
      font-size: 18px;
      line-height: 32px;
    }
  `}

  ${media.xl`
    padding-top: 48px;

    p {
      font-size: 20px;
      line-height: 40px;
    }
  `}
`;

const MediaWrapper = styled(ContentBlock)`
  display: none;

  ${media.md`
    display: flex;
    align-items: flex-start;
    padding-top: 60px;
  `}

  ${media.xl`
    padding-top: 70px;
    align-items: center;
  `}
`;

const Header2 = () => {
  const { messages } = useIntl();
  const heroImgPath = messages['wallet.header.image'].replace('-sm.png', '');
  const mediaImgPath = messages['wallet.header.media'].replace('.png', '');

  return (
    <StyledSection>
      <ContentBlock
        flip="vertical"
        style={{ position: 'relative', alignItems: 'center' }}
        aside={
          <Image
            maxHeight="unset"
            maxWidth="unset"
            src={messages['wallet.header.image']}
            alt={messages['wallet.header.title']}
            sizes="(max-width: 767px) 231w, (max-width: 1199px) 323w, (min-width: 1200px) 480w"
            srcSet={`${heroImgPath}-sm.png 231w, ${heroImgPath}-md.png 323w, ${heroImgPath}-sm@2x.png 462w, ${heroImgPath}-lg.png 480w,  ${heroImgPath}-md@2x.png 646w, ${heroImgPath}-lg@2x.png 958w`}
          />
        }
      >
        <H1>
          <FormattedMessage id="wallet.header.title" />
        </H1>
        <Paragraph>
          <FormattedMessage id="wallet.header.subtitle" />
        </Paragraph>
        <DownloadLinks />
      </ContentBlock>
      <MediaWrapper>
        <Image
          maxWidth="unset"
          maxHeight="unset"
          src={messages['wallet.header.media']}
          alt="media graphic"
          srcSet={`${mediaImgPath}.png 994w, ${mediaImgPath}@2x.png 1988w`}
        />
      </MediaWrapper>
    </StyledSection>
  );
};

export default Header2;
