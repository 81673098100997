import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import H5 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H5';
import Link from '@bitcoin-portal/bitcoincom-pkg-components/dist/Link';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Download from '@bitcoin-portal/bitcoincom-pkg-components/dist/Download';
import { StyledCard, ImageWrapper, StyledImage, Apps, Content } from './styled';

const Wallet = () => {
  const { messages } = useIntl();
  const imgPath = messages['buy.wallet.image'].replace('-sm.png', '');

  return (
    <Section>
      <StyledCard padded>
        <div>
          <ImageWrapper>
            <StyledImage
              maxWidth="unset"
              maxHeight="inherit"
              src={messages['buy.wallet.image']}
              alt={messages['buy.wallet.alt']}
              sizes="(max-width: 767px) 231w, (max-width: 1199px) 323w, (min-width: 1200px) 323w"
              srcSet={`${imgPath}-sm.png 231w, ${imgPath}-md.png 323w, ${imgPath}-sm@2x.png 462w, ${imgPath}-lg.png 499w, ${imgPath}-md@2x.png 646w, ${imgPath}-lg@2x.png 900w`}
            />
          </ImageWrapper>
        </div>
        <Content>
          <H5>
            <FormattedMessage id="buy.wallet.title" />
          </H5>
          <Paragraph bold style={{ color: colors.solid.primaryBlue }}>
            <FormattedMessage id="buy.wallet.subtitle" />
          </Paragraph>
          <Paragraph
            className="description"
            style={{ color: colors.solid.textTwo }}
          >
            <FormattedMessage id="buy.wallet.description" />
          </Paragraph>
          <Apps>
            <div>
              <Download
                type={Download.Types.AppStore}
                link={messages['buy.wallet.link-ios']}
              />
              <Download
                type={Download.Types.PlayStore}
                link={messages['buy.wallet.link-android']}
              />
            </div>
            <Link
              newTab
              href="https://wallet.bitcoin.com/download-sms"
              color="#666"
              style={{
                marginTop: 16,
                maxWidth: 'fit-content',
              }}
            >
              {messages['buy.wallet.sms']}
            </Link>
          </Apps>
        </Content>
      </StyledCard>
    </Section>
  );
};

export default Wallet;
