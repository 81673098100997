import styled from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import PriceChart from '@bitcoin-portal/bitcoincom-pkg-components/dist/PriceChart';

export const StyledContentBlock = styled(ContentBlock)`
  & > div {
    display: flex;
    justify-content: center;
  }

  ${media.md`
    flex-flow: column nowrap;
    align-items: center;

    & > div:first-child {
      justify-content: center;
      align-self: center;
    }
  `}

  ${media.lg`
    flex-flow: row nowrap;
    align-items: flex-start;
    min-height: 583px;

    & > div:first-child {
      justify-content: flex-start;
    }
  `}

  ${media.xl`
    & > div:first-child {
      justify-content: center;
    }
  `}
`;

export const Heading = styled.div`
  margin: auto;
  text-align: center;

  p {
    margin: auto;
    color: ${colors.solid.textTwo};
    width: 80%;
  }

  ${media.lg`
    padding-bottom: 65px;

    p {
      max-width: 600px;
      margin: 0 auto;
      padding-bottom: 0;
    }
  `}
`;

export const ImageWrapper = styled.div`
  height: 318px;

  ${media.md`
    height: 400px;
  `};

  ${media.xl`
    height: 550px;
  `}
`;

export const MediaWrapper = styled(ContentBlock)`
  display: none;

  ${media.md`
    display: flex;
    align-items: center;
    padding-top: 100px;
  `}
`;

export const ChartWrapper = styled.div`
  h3 {
    text-align: center;
  }
  h5 {
    line-height: 1.3;
    font-size: 22px;
    text-align: center;
  }
  .supply {
    font-weight: bold;
    color: ${colors.solid.primaryBlue};
  }
  ${media.md`
    h3 {
      text-align: left;
    }
    h5 {
      text-align: left;
      font-size: 30px;
    }
  `}
`;

export const StyledChart = styled(PriceChart)`
  margin: 0 0 0 -40px;
  > div {
    padding-bottom: 40%;
  }
`;
