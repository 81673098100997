import styled from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const Wrapper = styled.div`
  margin-top: 47px;
  padding: 30px 0;

  & > div > div:last-child {
    display: none;
  }

  & > div > div > h2 {
    color: ${colors.solid.textOne};
    text-align: center;
  }

  & > div > div > h2 > span > span {
    color: ${colors.solid.primaryBlue};
  }

  ${media.md`
    background: ${colors.solid.primaryBlue};
    margin-top: 80px;
    padding: 45px 0;

    & > div {
      align-items: center;
    }

    & > div > div:first-child {
      flex-basis: 90%;
    }

    & > div > div:last-child {
      display: flex;
      justify-content: flex-end;
    }

    & > div > div > h2 {
      color: rgba(247, 250, 253, 0.7);
      font-size: 32px;
      line-height: 38px;
      padding-bottom: 0;
      text-align: left;
    }

    & > div > div > h2 > span > span {
      color: white;
    }
  `}

  ${media.xl`
    padding: 62px 0;

    & > div > div > h2 {
      font-size: 51px;
      line-height: 61px;
    }
  `}
`;
