import React from 'react';
import { FormattedMessage } from 'react-intl';
import H1 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H1';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';

const Header = () => (
  <Section>
    <ContentBlock>
      <H1 align="center">
        <FormattedMessage id="sitemap.header.title" />
      </H1>
    </ContentBlock>
  </Section>
);

export default Header;
