import React from 'react';
import { useIntl } from 'react-intl';

import Navbar from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';

import SEO from './SEO';
import Header from './Header';
import BuySellTrade from './BuySellTrade';
import MoveMoney from './MoveMoney';
import Markets from './Markets';
import Manage from './Manage';
import Security from './Security';
import ERC from './ERC';
import { HeadSection } from './styled';
import { appLocales } from '../../i18n';

const Wallet = () => {
  const { messages, locale } = useIntl();

  return (
    <>
      <SEO />
      <HeadSection>
        <Navbar
          locale={locale}
          buttons={messages['global.nav.buttons']}
          links={messages['global.nav.links']}
        />
        <Header />
      </HeadSection>
      <BuySellTrade />
      <Security />
      <ERC />
      <Manage />
      <Markets />
      <MoveMoney />
      <Footer
        locale={locale}
        localeList={Object.keys(appLocales)}
        cookieName="bitcoinjp-locale"
        legal={messages['global.footer.legal']}
        links={messages['global.footer.links']}
        defaultLocale="ja"
      />
    </>
  );
};

export default Wallet;
