exports.DEFAULT_LOCALE = 'ja';
exports.appLocales = {
  ja: {
    path: 'ja',
    locale: '日本語',
    default: true,
  },
  en: {
    path: 'en',
    locale: 'English',
  },
  /*
  zh: {
    path: 'zh',
    locale: '中文',
  },
  ru: {
    path: 'ru',
    locale: 'Русский',
  },
  es: {
    path: 'es',
    locale: 'Español',
  },
    ko: {
      path: 'ko',
      locale: '한국어',
    },
    fr: {
      path: 'fr',
      locale: 'Français',
    },
  */
};
