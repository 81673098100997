import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

const SEO = () => {
  const { formatMessage, messages } = useIntl();

  return (
    <Helmet title={formatMessage({ id: 'wallet.meta.title' })}>
      <meta
        name="description"
        content={formatMessage({
          id: 'wallet.meta.description',
        })}
      />
      <meta
        name="apple-itunes-app"
        content="app-id=1252903728, app-argument=https://www.bitcoin.jp/wallet"
      />
      <meta
        property="og:title"
        content={formatMessage({ id: 'wallet.meta.title' })}
      />
      <meta
        property="og:description"
        content={formatMessage({
          id: 'wallet.meta.description',
        })}
      />
      <meta
        property="og:image"
        content={`https://www.bitcoin.jp${messages['wallet.meta.image']}`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:site"
        content={`@${messages['wallet.meta.twitter']}`}
      />
      <meta
        name="twitter:title"
        content={formatMessage({ id: 'wallet.meta.title' })}
      />
      <meta
        name="twitter:description"
        content={formatMessage({
          id: 'wallet.meta.description',
        })}
      />
      <meta
        name="twitter:image"
        content={`https://www.bitcoin.jp${messages['wallet.meta.image']}`}
      />
      <script type="application/ld+json">
        {JSON.stringify(messages['wallet.meta.schema'])}
      </script>
      <link rel="canonical" href="https://www.bitcoin.jp/" />
    </Helmet>
  );
};

export default SEO;
