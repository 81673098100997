import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import merge from 'lodash/merge';

import flattenMessages from '../helpers/flattenMessages';
import { DEFAULT_LOCALE, appLocales } from '.';

import errror404 from './404';
import aboutUs from './about-us';
import buy from './buy';
import contactUs from './contact-us';
import cookies from './legal/cookies';
import dsarForm from './legal/dsar-form';
import faq from './legal/faq';
import global from './global';
import home from './home';
import jobs from './jobs';
import privacyPolicy from './legal/privacy-policy';
import sitemap from './sitemap';
import userAgreement from './legal/user-agreement';
import wallet from './wallet';
import walletServiceTerms from './legal/wallet-service-terms';

const messagesMap = {
  home,
  404: errror404,
  'about-us': aboutUs,
  buy,
  'contact-us': contactUs,
  cookies,
  'dsar-form': dsarForm,
  faq,
  global,
  jobs,
  'privacy-policy': privacyPolicy,
  sitemap,
  'user-agreement': userAgreement,
  wallet,
  'wallet-service-terms': walletServiceTerms,
};

const Intl = props => {
  const { localeDir, children, locale, netlify } = props;
  // console.log(localeDir, locale);
  const splitLocaleDir = localeDir
    .split('/')
    .reduce((prev, curr) => (curr === '' ? prev : [...prev, curr]), []);

  // console.log(splitLocaleDir);
  let pageName = '404';

  Object.keys(messagesMap).forEach(value => {
    if (splitLocaleDir.includes(value)) pageName = value;
  });

  pageName = splitLocaleDir[splitLocaleDir.length - 1];

  if (splitLocaleDir.includes('get-started')) pageName = 'get-started';
  if (splitLocaleDir.length === 0) pageName = 'home';
  if (
    splitLocaleDir.length === 1 &&
    Object.keys(appLocales).includes(splitLocaleDir[0])
  )
    pageName = 'home';
  /* locale === DEFAULT_LOCALE && splitLocaleDir.length === 0
      ? 'home'
      : splitLocaleDir[0]; */

  // console.log(pageName);
  const locales = messagesMap[pageName] || messagesMap['404'];

  Object.keys(locales).forEach(lang => {
    locales[lang] = {
      ...locales[lang],
      ...messagesMap.global[lang],
      ...messagesMap['404'][lang],
    };
  });

  // console.log(locales[locale]);
  const messages = netlify
    ? merge({}, locales[DEFAULT_LOCALE], {
        ...flattenMessages(netlify, pageName),
      })
    : merge({}, locales[DEFAULT_LOCALE], locales[locale]);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      messages={messages}
    >
      {children}
    </IntlProvider>
  );
};

Intl.propTypes = {
  locale: PropTypes.string.isRequired,
  localeDir: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  netlify: PropTypes.shape({}),
};

Intl.defaultProps = {
  netlify: undefined,
};

export default Intl;
