import styled from 'styled-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: left;

  & > hr {
    width: 100%;
    margin: 0 0 16px;
    ${media.md`
      margin: 16px 0 48px;
    `}
  }
`;

export const MainPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 16px;

  ${media.sm`
    width: 50%;
  `}
  ${media.md`
    width: 25%;
  `}

  & > div {
    margin-bottom: 8px;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
